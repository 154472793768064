import {
    ADD_DOMAIN,
    ADD_DOMAIN_FAILURE,
    ADD_DOMAIN_SUCCESS,
    DELETE_DOMAIN,
    DELETE_DOMAIN_FAILURE,
    DELETE_DOMAIN_SUCCESS,
    EDIT_DOMAIN,
    EDIT_DOMAIN_FAILURE,
    EDIT_DOMAIN_SUCCESS,
    GET_DOMAINS,
    GET_DOMAINS_FAILURE,
    GET_DOMAINS_SUCCESS,
    GET_SINGLE_DOMAIN,
    GET_SINGLE_DOMAIN_FAILURE,
    GET_SINGLE_DOMAIN_SUCCESS,
} from "./actionTypes";

export const getDomains = (payload) => {
    return {
        type: GET_DOMAINS,
        payload: payload,
    };
};

export const getDomainsSuccess = (domains) => {
    return {
        type: GET_DOMAINS_SUCCESS,
        payload: domains,
    };
};

export const getDomainsFailure = (error) => {
    return {
        type: GET_DOMAINS_FAILURE,
        payload: error,
    };
};

export const getSingleDomain = (id) => {
    return {
        type: GET_SINGLE_DOMAIN,
        payload: id,
    };
};

export const getSingleDomainsuccess = (domain) => {
    return {
        type: GET_SINGLE_DOMAIN_SUCCESS,
        payload: domain,
    };
};

export const getSingleDomainFailure = (error) => {
    return {
        type: GET_SINGLE_DOMAIN_FAILURE,
        payload: error,
    };
};

export const addDomain = (domain) => {
    return {
        type: ADD_DOMAIN,
        payload: domain,
    };
};

export const addDomainSuccess = (domain) => {
    return {
        type: ADD_DOMAIN_SUCCESS,
        payload: domain,
    };
};

export const addDomainFailure = (error) => {
    return {
        type: ADD_DOMAIN_FAILURE,
        payload: error,
    };
};

export const editDomain = (domain) => {
    return {
        type: EDIT_DOMAIN,
        payload: domain,
    };
};

export const editDomainSuccess = (domain) => {
    return {
        type: EDIT_DOMAIN_SUCCESS,
        payload: domain,
    };
};

export const editDomainFailure = (error) => {
    return {
        type: EDIT_DOMAIN_FAILURE,
        payload: error,
    };
};

export const deleteDomain = (id) => {
    return {
        type: DELETE_DOMAIN,
        payload: id,
    };
};

export const deleteDomainSuccess = (id) => {
    return {
        type: DELETE_DOMAIN_SUCCESS,
        payload: id,
    };
};

export const deleteDomainFailure = (error) => {
    return {
        type: DELETE_DOMAIN_FAILURE,
        payload: error,
    };
};
