import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { Editor } from "components/shared/Editor";

import {
  editBlog,
  getBlogCategories,
  getSettings,
  getSingleBlog,
  getTags,
} from "store/actions";

import { useNavigate, useParams } from "react-router-dom";
import Loader from "components/shared/Loader";
import CreatableSelect from "react-select/creatable";
import ImageUpload from "components/shared/ImageUpload";

const EditProduct = () => {
  const { locale } = useIntl();
  const dir = locale === "ar" ? "rtl" : "ltr";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedImage, setSelectedImage] = useState({
    preview: null,
    path: null,
  });
  const [description, setDescription] = useState({ ar: "", en: "" });
  const [tags, setTags] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const { singleBlog, loading, blogTags } = useSelector((state) => state.blogs);
  const { blogCategories } = useSelector((state) => state.blogCategories);

  const { settings } = useSelector((state) => state.settings || {});
  const { languages } = settings;

  useEffect(() => {
    dispatch(getBlogCategories());
    dispatch(getSettings("languages"));
    dispatch(getTags());
    dispatch(getSingleBlog(id));
  }, [dispatch, id]);

  useEffect(() => {
    reset({
      title: singleBlog.title,
      language: singleBlog.language?.id,
      category: singleBlog.category?.id,
      image: singleBlog.image,
      tags: singleBlog.tags,
      description: singleBlog.description,
    });
    setSelectedImage({
      preview: "",
      path: singleBlog.image,
    });
  }, [reset, singleBlog]);

  const onSubmit = (data) => {
    if (selectedImage && selectedImage.path) data["image"] = selectedImage.path;
    // data.image = "uploads/" + data.image.split("/").pop();

    data.description = description;
    data.tags = tags;

    dispatch(editBlog({ id, data, navigate }));
  };

  const renderCategoriesSelectOptions = () => {
    return blogCategories?.map((category) => {
      return (
        <option value={category.id} key={category.id}>
          {category?.name?.[locale]}
        </option>
      );
    });
  };

  const renderLanguagesSelectOptions = () => {
    return languages?.map((language, index) => {
      return (
        <option value={language?._id} key={index}>
          {language?.name}
        </option>
      );
    });
  };

  useEffect(() => {
    setTags(singleBlog.tags);
  }, [singleBlog]);

  useEffect(() => {
    setDescription(singleBlog.description);
  }, [singleBlog]);

  const renderTags = () => {
    return singleBlog?.tags?.map((tag) => {
      return {
        label: tag,
        value: tag,
      };
    });
  };

  if (loading) return <Loader />;

  return (
    <>
      <div className="acc-form">
        <div className="card">
          <div className="card-head">
            <h4>تعديل المقال</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Col xl={10} md={12}>
                <Row>
                  <Col lg={12}>
                    <div className="form-group required">
                      <h5>صورة المقال</h5>

                      <ImageUpload
                        className="m-1"
                        selectedImage={selectedImage}
                        setSelectedImage={setSelectedImage}
                      />
                    </div>
                  </Col>
                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>التصنيف</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("category", { required: true })}
                        >
                          <option value="">اختر التصنيف</option>
                          {renderCategoriesSelectOptions()}
                        </select>
                      </div>
                      {errors?.category?.type === "required" && (
                        <p className="error-hint">هذا الحقل مطلوب</p>
                      )}
                    </div>
                  </Col>

                  <Col lg={6} xs={12}>
                    <div className="form-group required">
                      <h5>لغة المقال</h5>
                      <div>
                        <select
                          className="form-control"
                          {...register("language", { required: true })}
                        >
                          <option value="">اختر لغة المقال</option>
                          {renderLanguagesSelectOptions()}
                        </select>
                      </div>
                      {errors?.language?.type === "required" && (
                        <p className="error-hint">هذا الحقل مطلوب</p>
                      )}
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>عنوان المقال</h5>
                      <div>
                        <input
                          type="text"
                          className="form-control form-outline"
                          placeholder="عنوان المقال"
                          {...register("title", { required: true })}
                        />
                      </div>
                      {errors?.title?.type === "required" && (
                        <p className="error-hint">هذا الحقل مطلوب</p>
                      )}
                    </div>
                  </Col>

                  <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>وصف المقال</h5>
                      <div>
                        <Controller
                          control={control}
                          name="description"
                          render={({ field: { value } }) => (
                            <Editor
                              initialValue={singleBlog?.description}
                              onChange={(e, editor) => {
                                const data = editor.getContent();
                                setDescription(data);
                              }}
                              tinymceScriptSrc="https://cdn.tiny.cloud/1/qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc/tinymce/5-stable/tinymce.min.js"
                              init={{
                                plugins:
                                  "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
                                menubar:
                                  "file edit view insert format tools table help",
                                toolbar:
                                  "customInsertButton | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | fullscreen  preview print | insertfile image media link table | ltr rtl | language",
                                toolbar_sticky: true,
                                autosave_ask_before_unload: true,
                                autosave_interval: "30s",
                                autosave_prefix: "{path}{query}-{id}-",
                                autosave_restore_when_empty: false,
                                autosave_retention: "2m",
                                mobile: {
                                  menubar: true,
                                },
                                directionality: `${dir}`,
                                language: `${locale}`,
                                height: 400,
                                image_caption: true,
                                quickbars_selection_toolbar:
                                  "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
                                noneditable_noneditable_class: "mceNonEditable",
                                toolbar_mode: "wrap",
                                contextmenu: "link image imagetools table",
                                content_style: "body {font-size:14px}",
                                setup: function (editor) {
                                  editor.ui.registry.addButton(
                                    "customInsertButton",
                                    {
                                      text: "custom Button",
                                      onAction: function (_) {
                                        editor.insertContent(
                                          `&nbsp;<a href="" class="btn" style="    background: linear-gradient(
																148deg,#1d5cd1,#0ba1d8 84%);
															color: #fff;
															padding: 10px 20px;
															border-radius: 50px;
															display: inline-block;
															cursor: pointer;
															text-decoration: none;">my button!</a>&nbsp;`
                                        );
                                      },
                                    }
                                  );
                                },
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="form-group">
                      <h5>اضافة كلمات دلالية</h5>
                      <div>
                        <Controller
                          control={control}
                          name="tags"
                          render={({
                            field: { onChange, value, selected },
                          }) => (
                            <CreatableSelect
                              isMulti
                              isRtl={true}
                              className="basic-single creatable-select"
                              classNamePrefix="select"
                              menuPlacement="top"
                              options={blogTags.map((tag) => ({
                                value: tag,
                                label: tag,
                              }))}
                              defaultValue={
                                renderTags()?.filter((tag) => {
                                  return tag;
                                }) || []
                              }
                              onChange={(selected) => {
                                const newTags = selected.map(
                                  (tag) => tag.label
                                );

                                setTags(newTags);
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4} xs={12}>
                    <div className="form-group">
                      <button type="submit" className="btn btn-blue">
                        حفظ التعديلات
                      </button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProduct;
