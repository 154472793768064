import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Nav, Row, Tab } from "react-bootstrap";
import Flatpickr from "react-flatpickr";
import { Controller, useForm } from "react-hook-form";
import SelectMultiple from "react-select";

import { ReactComponent as ModalHeaderIcon } from "assets/svgs/coupon/coupon.svg";
import { ReactComponent as CodeIcon } from "assets/svgs/coupon/coupon-code.svg";
import { ReactComponent as StartDateIcon } from "assets/svgs/coupon/start-date.svg";
import { ReactComponent as EndDateIcon } from "assets/svgs/coupon/end-date.svg";
import { ReactComponent as UsageTimesIcon } from "assets/svgs/coupon/used-times.svg";
import { ReactComponent as EmailIcon } from "assets/svgs/coupon/email.svg";
import { ReactComponent as ShipIcon } from "assets/svgs/product/truck.svg";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { addCoupon, editCoupon } from "store/actions";
import { getId } from "helpers/functions";
import { priceInputLength, priceInputValidations } from "helpers/configs";

const CouponModal = ({ show, setShow, handleClose, coupon, type }) => {
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const [typeValue, setTypeValue] = useState("amount");
  // eslint-disable-next-line no-unused-vars
  const [isFreeShipping, setIsFreeShipping] = useState(false);
  const [marketCoupon, setMarketCoupon] = useState(true);

  const { error } = useSelector((state) => state.coupons);
  const { products } = useSelector((state) => state.products);
  const { users } = useSelector((state) => state.customers);
  const { brands } = useSelector((state) => state.brands);
  const { flatCategories } = useSelector((state) => state.productCategories);

  const renderSelectOptions = (dataArray = [], field, isLocale = false) => {
    if (dataArray.length === 0) return [];
    if (!field) return;

    let options = [];

    if (isLocale) {
      options = dataArray?.map((element) => ({
        label: element?.[field]?.[locale],
        value: getId(element),
      }));
    } else {
      options = dataArray?.map((element) => ({
        label: element[field],
        value: getId(element),
      }));
    }
    return options;
  };

  useEffect(() => {
    error?.errors?.map((e) =>
      setError(e.param, { type: "manual", message: e?.msg })
    );
  }, [error, setError]);

  useEffect(() => {
    if (type === "add") reset();
    return () => reset({});
  }, [reset, type]);

  useEffect(() => {
    reset({
      code: coupon?.code,
      type: coupon?.type,
      amount: coupon?.amount,
      startDate: coupon?.startDate,
      endDate: coupon?.endDate,
      maxAllUsageCount: coupon?.maxAllUsageCount,
      maxUserUsageCount: coupon?.maxUserUsageCount,
      isFreeShipping: coupon?.isFreeShipping,
      minimumPurchases: coupon?.minimumPurchases,
      isMarketingCoupon: coupon?.isMarketingCoupon,
      clientEmailDomain: coupon?.clientEmailDomain,
      maximumDiscountAmount: coupon?.maximumDiscountAmount,
      includeCategories: coupon?.includeCategories?.map((e) => getId(e)),
      includeProducts: coupon?.includeProducts?.map((e) => getId(e)),
      includeUsers: coupon?.includeUsers?.map((e) => getId(e)),
    });
    return () => reset({});
  }, [coupon, reset]);
  function isoDateWithoutTimeZone(date) {
    if (date == null) return date;
    date = new Date(date);
    var timestamp = date.getTime() - date.getTimezoneOffset() * 60000;
    var correctDate = new Date(timestamp);
    // correctDate.setUTCHours(0, 0, 0, 0); // uncomment this if you want to remove the time
    return correctDate.toISOString();
  }

  const errorDisplay = (key) =>
    errors?.[key] ? (
      <p className="text-danger">
        {errors?.[key]?.type === "required" ? (
          <FormattedMessage id="required" />
        ) : (
          <FormattedMessage id={errors?.[key]?.message} />
        )}
      </p>
    ) : null;

  const onSubmit = (data) => {
    if (`${data?.code}`?.trim()?.length === 0) return setValue("code", "");

    data.startDate = isoDateWithoutTimeZone(data.startDate);
    data.endDate = isoDateWithoutTimeZone(data.endDate);

    if (type === "add") {
      dispatch(
        addCoupon({
          data,
          callBack: () => setShow(false),
        })
      );
      // setShow(false);
    } else {
      dispatch(
        editCoupon({
          data,
          id: getId(coupon),
          callBack: () => setShow(false),
        })
      );
      // setShow(false);
    }
  };

  return (
    <div className="productModal">
      <Modal
        dialogClassName="productDataModal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Tab.Container defaultActiveKey={0} transition={true} timeout={1000}>
            <Modal.Header closeButton>
              <Modal.Title>
                <ModalHeaderIcon fill="#FC6B14" />
                الكوبونات
              </Modal.Title>
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey={0} className="btn">
                    بيانات الــكوبون
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={1} className="btn">
                    مـشمول في الكـوبون
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={2} className="btn">
                    مثتثني من الكوبون
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Modal.Header>
            <Modal.Body>
              <Tab.Content>
                <Tab.Pane eventKey={0}>
                  <div className="form-group">
                    <div className="form-body">
                      <Row>
                        <Col xs={12} className="form-group mb-0 pb-0 required">
                          <h5 htmlFor="">
                            كود الكوبون(حروف انجليزية وارقام وبدون مسافات)
                          </h5>
                          <div>
                            <input
                              type="text"
                              id=""
                              className="form-control"
                              placeholder="أدخل كود الكوبون"
                              {...register("code", { required: true })}
                            />
                            <CodeIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("code")}
                        </Col>

                        <Col xs={12}>
                          <label htmlFor=""> نوع الخصم للعميل </label>
                          <div>
                            <select
                              className="form-control"
                              {...register("type")}
                              onChange={(e) => {
                                setTypeValue(e.target.value);
                              }}
                            >
                              <option value="amount">مبلغ ثابت</option>
                              <option value="percent">نسبة مئوية</option>
                            </select>
                            <ModalHeaderIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("type")}
                        </Col>

                        <Col xs={12} className="form-group mb-0 pb-0 required">
                          <h5 htmlFor="">
                            {typeValue === "amount"
                              ? "قيمة الخصم ( مبلغ ثابت )"
                              : "نسبة الخصم ( نسبة مئوية )"}
                          </h5>
                          <div>
                            <input
                              type="text"
                              id=""
                              className="form-control"
                              {...priceInputValidations}
                              placeholder={
                                typeValue === "amount"
                                  ? "أدخل قيمة الخصم"
                                  : "أدخل نسبة الخصم"
                              }
                              maxLength={
                                typeValue === "amount" ? priceInputLength : "3"
                              }
                              {...register("amount", {
                                required: true,
                              })}
                            />
                            <ModalHeaderIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("amount")}
                        </Col>

                        {typeValue === "percent" ? (
                          <Col xs={12}>
                            <label htmlFor="">المبلغ الأقصى للتخفيض</label>
                            <div>
                              <input
                                type="text"
                                id=""
                                className="form-control"
                                placeholder="المبلغ الأقصى للتخفيض"
                                {...register("maximumDiscountAmount")}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                            {errorDisplay("maximumDiscountAmount")}
                          </Col>
                        ) : null}

                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="required form-group mb-0 pb-0"
                        >
                          <h5 htmlFor=""> تاريخ بداية الكوبون </h5>
                          <div>
                            <Controller
                              control={control}
                              name="startDate"
                              rules={{ required: true }}
                              render={({ field: { onChange } }) => (
                                <Flatpickr
                                  className="form-control form-outline"
                                  placeholder="تاريخ بداية الكوبون"
                                  onChange={(date) => {
                                    onChange(`${date?.[0] || date}`);
                                  }}
                                  value={coupon?.startDate}
                                  options={{
                                    monthSelectorType: "dropdown",
                                  }}
                                />
                              )}
                            />
                            <StartDateIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("startDate")}
                        </Col>

                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="required form-group mb-0 pb-0"
                        >
                          <h5 htmlFor=""> نهاية التخفيض </h5>
                          <div>
                            <Controller
                              control={control}
                              name="endDate"
                              rules={{ required: true }}
                              render={({ field: { onChange } }) => (
                                <Flatpickr
                                  className="form-control form-outline"
                                  placeholder="نهاية التخفيض"
                                  onChange={(date) => {
                                    onChange(`${date?.[0] || date}`);
                                  }}
                                  value={coupon?.endDate}
                                />
                              )}
                            />
                            <EndDateIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("endDate")}
                        </Col>

                        <Col xs={12}>
                          <label htmlFor=""> مع شحن مجاني؟ </label>
                          <div>
                            <select
                              className="form-control"
                              {...register("isFreeShipping")}
                              onChange={(e) => {
                                setIsFreeShipping(e.target.value);
                              }}
                            >
                              <option value="false">لا</option>
                              <option value="true">نعم</option>
                            </select>
                            <ShipIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("isFreeShipping")}
                        </Col>

                        <Col xs={12}>
                          <label htmlFor=""> الحد الادنى من المشتريات </label>
                          <div>
                            <input
                              className="form-control"
                              {...priceInputValidations}
                              placeholder="الحد الادنى من المشتريات"
                              {...register("minimumPurchases")}
                            />
                            <CodeIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("minimumPurchases")}
                        </Col>

                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="required form-group mb-0 pb-0"
                        >
                          <h5 htmlFor=""> عدد مرات الاستخدام للجميع </h5>
                          <div>
                            <input
                              type="number"
                              id=""
                              className="form-control"
                              placeholder="عدد مرات الاستخدام للجميع"
                              {...register("maxAllUsageCount", {
                                required: true,
                              })}
                            />
                            <UsageTimesIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("maxAllUsageCount")}
                        </Col>

                        <Col
                          xxl={6}
                          xl={6}
                          lg={6}
                          md={6}
                          sm={12}
                          xs={12}
                          className="required form-group mb-0 pb-0"
                        >
                          <h5 htmlFor="">عدد مرات الاستخدام للعميل الواحد</h5>
                          <div>
                            <input
                              type="number"
                              id=""
                              className="form-control"
                              placeholder="عدد مرات الاستخدام للعميل الواحد"
                              {...register("maxUserUsageCount", {
                                required: true,
                              })}
                            />
                            <UsageTimesIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("maxUserUsageCount")}
                        </Col>

                        <Col xs={12}>
                          <div className="marketCoupon">
                            <div>
                              <h4> كوبون تسويقي </h4>
                              <p>
                                ادخل بيانات المسوّ ق والعمولة الخاصة به من
                                المبيعات، كما يمكن مشاركة رابط الاحصائيات بعد
                                إنشاء الكوبون
                              </p>
                            </div>
                            <label className="switcher">
                              <input
                                type="checkbox"
                                className="switcher-input"
                                defaultChecked={
                                  coupon?.isMarketingCoupon === true
                                    ? true
                                    : false
                                }
                                onChange={() => {
                                  setMarketCoupon(!marketCoupon);
                                }}
                                {...register("isMarketingCoupon")}
                              />
                              <div className="knobs"> </div>
                              <div className="layer"> </div>
                            </label>
                          </div>
                          {errorDisplay("isMarketingCoupon")}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={1}>
                  <div className="form-group">
                    <div className="form-body">
                      <Row>
                        <Col xs={12}>
                          <div className="selectHolder">
                            <label> تصنيفات مشمولة </label>
                            <div>
                              <Controller
                                control={control}
                                name="includeCategories"
                                render={({
                                  field: { onChange, value: selectedValues },
                                }) => (
                                  <>
                                    <SelectMultiple
                                      isRtl={true}
                                      isSearchable={false}
                                      className="basic-single"
                                      classNamePrefix="select"
                                      options={renderSelectOptions(
                                        flatCategories,
                                        "name",
                                        true
                                      )}
                                      placeholder="تصنيفات مشمولة"
                                      isMulti={true}
                                      onChange={(selected) => {
                                        onChange(
                                          selected.map(
                                            (selected) => selected?.value
                                          )
                                        );
                                      }}
                                      // value={(selectedValues || [])?.map(
                                      //   (e) => ({
                                      //     label: e,
                                      //     value: e,
                                      //   })
                                      // )}
                                      value={renderSelectOptions(
                                        flatCategories?.filter((category) =>
                                          selectedValues?.includes(
                                            getId(category)
                                          )
                                        ),
                                        "name",
                                        true
                                      )}
                                      // defaultValue={renderSelectOptions(
                                      //   coupon?.includeCategories,
                                      //   "name",
                                      //   true
                                      // )}
                                    />
                                  </>
                                )}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                          </div>
                          {errorDisplay("includeCategories")}
                        </Col>
                        <Col xs={12}>
                          <div className="selectHolder">
                            <label> منتجات مشمولة </label>
                            <div>
                              <Controller
                                control={control}
                                name="includeProducts"
                                render={({
                                  field: { onChange, value: selectedValues },
                                }) => (
                                  <SelectMultiple
                                    isRtl={true}
                                    isSearchable={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={renderSelectOptions(
                                      products,
                                      "name",
                                      true
                                    )}
                                    placeholder="منتجات مشمولة"
                                    isMulti={true}
                                    onChange={(selected) => {
                                      onChange(
                                        selected.map(
                                          (selected) => selected?.value
                                        )
                                      );
                                    }}
                                    value={renderSelectOptions(
                                      products?.filter((category) =>
                                        selectedValues?.includes(
                                          getId(category)
                                        )
                                      ),
                                      "name",
                                      true
                                    )}
                                    // defaultValue={renderSelectOptions(
                                    //   coupon?.includeProducts,
                                    //   "name",
                                    //   true
                                    // )}
                                  />
                                )}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                          </div>
                          {errorDisplay("includeProducts")}
                        </Col>
                        <Col xs={12}>
                          <div className="selectHolder">
                            <label> عملاء مشمولة </label>
                            <div>
                              <Controller
                                control={control}
                                name="includeUsers"
                                render={({
                                  field: { onChange, value: selectedValues },
                                }) => (
                                  <SelectMultiple
                                    isRtl={true}
                                    isSearchable={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={renderSelectOptions(
                                      users,
                                      "username",
                                      false
                                    )}
                                    placeholder="عملاء مشمولة"
                                    isMulti={true}
                                    onChange={(selected) => {
                                      onChange(
                                        selected.map(
                                          (selected) => selected?.value
                                        )
                                      );
                                    }}
                                    value={renderSelectOptions(
                                      users?.filter((category) =>
                                        selectedValues?.includes(
                                          getId(category)
                                        )
                                      ),
                                      "username",
                                      false
                                    )}
                                    // defaultValue={renderSelectOptions(
                                    //   coupon?.includeUsers,
                                    //   "username"
                                    // )}
                                  />
                                )}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                          </div>
                          {errorDisplay("includeUsers")}
                        </Col>
                        <Col xs={12}>
                          <label htmlFor=""> دومين بريد العميل </label>
                          <div>
                            <input
                              type="email"
                              id=""
                              className="form-control"
                              placeholder="ادخل بريد الكتروني"
                              {...register("clientEmailDomain")}
                            />
                            <EmailIcon fill="#E4E4E4" />
                          </div>
                          {errorDisplay("clientEmailDomain")}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey={2}>
                  <div className="form-group">
                    <div className="form-body">
                      <Row>
                        <Col xs={12}>
                          <div className="selectHolder">
                            <label> تصنيفات مستثناة </label>
                            <div>
                              <Controller
                                control={control}
                                name="excludeCategories"
                                render={({
                                  field: { onChange, value: selectedValues },
                                }) => (
                                  <SelectMultiple
                                    isRtl={true}
                                    isSearchable={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={renderSelectOptions(
                                      flatCategories,
                                      "name",
                                      true
                                    )}
                                    placeholder="تصنيفات مستثناة"
                                    isMulti={true}
                                    onChange={(selected) => {
                                      onChange(
                                        selected.map(
                                          (selected) => selected?.value
                                        )
                                      );
                                    }}
                                    value={renderSelectOptions(
                                      flatCategories?.filter((category) =>
                                        selectedValues?.includes(
                                          getId(category)
                                        )
                                      ),
                                      "name",
                                      true
                                    )}
                                    // defaultValue={renderSelectOptions(
                                    //   coupon?.excludeCategories,
                                    //   "name",
                                    //   true
                                    // )}
                                  />
                                )}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                          </div>
                          {errorDisplay("excludeCategories")}
                        </Col>
                        <Col xs={12}>
                          <div className="selectHolder">
                            <label> ماركات مستثناة </label>
                            <div>
                              <Controller
                                control={control}
                                name="excludeBrands"
                                render={({
                                  field: { onChange, value: selectedValues },
                                }) => (
                                  <SelectMultiple
                                    isRtl={true}
                                    isSearchable={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={renderSelectOptions(
                                      brands,
                                      "name",
                                      true
                                    )}
                                    placeholder="ماركات مستثناة"
                                    isMulti={true}
                                    onChange={(selected) => {
                                      onChange(
                                        selected.map(
                                          (selected) => selected?.value
                                        )
                                      );
                                    }}
                                    value={renderSelectOptions(
                                      brands?.filter((category) =>
                                        selectedValues?.includes(
                                          getId(category)
                                        )
                                      ),
                                      "name",
                                      true
                                    )}
                                    // defaultValue={renderSelectOptions(
                                    //   coupon?.excludeBrands,
                                    //   "name",
                                    //   true
                                    // )}
                                  />
                                )}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                          </div>
                          {errorDisplay("excludeBrands")}
                        </Col>
                        <Col xs={12}>
                          <div className="selectHolder">
                            <label> منتجات مستثناة </label>
                            <div>
                              <Controller
                                control={control}
                                name="excludeProducts"
                                render={({
                                  field: { onChange, value: selectedValues },
                                }) => (
                                  <SelectMultiple
                                    isRtl={true}
                                    isSearchable={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={renderSelectOptions(
                                      products,
                                      "name",
                                      true
                                    )}
                                    placeholder="منتجات مستثناة"
                                    isMulti={true}
                                    onChange={(selected) => {
                                      onChange(
                                        selected.map(
                                          (selected) => selected?.value
                                        )
                                      );
                                    }}
                                    value={renderSelectOptions(
                                      products?.filter((category) =>
                                        selectedValues?.includes(
                                          getId(category)
                                        )
                                      ),
                                      "name",
                                      true
                                    )}
                                    // defaultValue={renderSelectOptions(
                                    //   coupon?.excludeProducts,
                                    //   "name",
                                    //   true
                                    // )}
                                  />
                                )}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                          </div>
                          {errorDisplay("excludeProducts")}
                        </Col>
                        <Col xs={12}>
                          <div className="selectHolder">
                            <label> مجموعة العملاء المستثناة</label>
                            <div>
                              <Controller
                                control={control}
                                name="excludeUsers"
                                render={({
                                  field: { onChange, value: selectedValues },
                                }) => (
                                  <SelectMultiple
                                    isRtl={true}
                                    isSearchable={false}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={renderSelectOptions(
                                      users,
                                      "username"
                                    )}
                                    placeholder=" مجموعة العملاء المستثناة"
                                    isMulti={true}
                                    onChange={(selected) => {
                                      onChange(
                                        selected.map(
                                          (selected) => selected?.value
                                        )
                                      );
                                    }}
                                    value={renderSelectOptions(
                                      users?.filter((category) =>
                                        selectedValues?.includes(
                                          getId(category)
                                        )
                                      ),
                                      "username"
                                    )}
                                    // defaultValue={renderSelectOptions(
                                    //   coupon?.excludeUsers,
                                    //   "username"
                                    // )}
                                  />
                                )}
                              />
                              <ModalHeaderIcon fill="#E4E4E4" />
                            </div>
                          </div>
                          {errorDisplay("excludeUsers")}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-outline"
                onClick={handleClose}
              >
                إلغاء
              </button>
              <button type="submit" className="btn btn-blue">
                حفظ
              </button>
            </Modal.Footer>
          </Tab.Container>
        </Form>
      </Modal>
    </div>
  );
};

export default CouponModal;
