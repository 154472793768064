import { getId } from "helpers/functions";
import {
  GET_SETTINGS,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILURE,
  EDIT_SETTINGS,
  EDIT_SETTINGS_SUCCESS,
  EDIT_SETTINGS_FAILURE,
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_SUCCESS,
  ADD_BANK_ACCOUNT_FAILURE,
  EDIT_BANK_ACCOUNT,
  EDIT_BANK_ACCOUNT_SUCCESS,
  EDIT_BANK_ACCOUNT_FAILURE,
  EDIT_STORE_BASIC_SETTINGS,
  EDIT_STORE_BASIC_SETTINGS_SUCCESS,
  EDIT_STORE_BASIC_SETTINGS_FAILURE,
  EDIT_STORE_SLUG_SETTING,
  EDIT_STORE_SLUG_SETTING_SUCCESS,
  EDIT_STORE_SLUG_SETTING_FAILURE,
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_FAILURE,
  SET_DEFAULT_BANK_ACCOUNT,
  SET_DEFAULT_BANK_ACCOUNT_SUCCESS,
  SET_DEFAULT_BANK_ACCOUNT_FAILURE,
  DELETE_BANK_ACCOUNT,
  DELETE_BANK_ACCOUNT_SUCCESS,
  DELETE_BANK_ACCOUNT_FAILURE,
  EDIT_STORE_DEFAULT_LANGUAGE,
  EDIT_STORE_DEFAULT_LANGUAGE_SUCCESS,
  EDIT_STORE_DEFAULT_LANGUAGE_FAILURE,
  EDIT_STORE_DEFAULT_CURRENCY,
  EDIT_STORE_DEFAULT_CURRENCY_SUCCESS,
  EDIT_STORE_DEFAULT_CURRENCY_FAILURE,
} from "./actionTypes";

const initialState = {
  settings: {},
  loading: false,
  bankAccount: true,
  dataSaveLoading: false,
  error: "",
  bankAccounts: [],
};

const stores = (state = initialState, action) => {
  switch (action.type) {
    // get all settings
    case GET_SETTINGS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: { ...state.settings, ...action.payload.settings },
      };
      break;

    case GET_SETTINGS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;
    // get all settings
    case GET_BANK_ACCOUNTS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case GET_BANK_ACCOUNTS_SUCCESS:
      state = {
        ...state,
        loading: false,
        bankAccounts: action.payload.bankAccounts,
      };
      break;

    case GET_BANK_ACCOUNTS_FAILURE:
      state = {
        ...state,
        error: action.payload,
        loading: false,
      };
      break;

    // edit MAIN Settings
    case EDIT_SETTINGS:
      state = { ...state, loading: true };

      break;

    case EDIT_SETTINGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: { ...state.settings, ...action.payload.settings },
      };
      break;

    case EDIT_SETTINGS_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    // edit store default language
    case EDIT_STORE_DEFAULT_LANGUAGE:
      state = { ...state, loading: true };
      break;
    case EDIT_STORE_DEFAULT_LANGUAGE_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: { ...state.settings, ...action.payload.settings },
      };
      break;
    case EDIT_STORE_DEFAULT_LANGUAGE_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    // edit store default Currency
    case EDIT_STORE_DEFAULT_CURRENCY:
      state = { ...state, loading: true };
      break;
    case EDIT_STORE_DEFAULT_CURRENCY_SUCCESS:
      state = {
        ...state,
        loading: false,
        settings: { ...state.settings, ...action.payload.settings },
      };
      break;
    case EDIT_STORE_DEFAULT_CURRENCY_FAILURE:
      state = {
        ...state,
        loading: false,
        error: action.payload,
      };
      break;
    //setting slug update

    case EDIT_STORE_BASIC_SETTINGS:
      state = { ...state, loading: true };
      break;
    case EDIT_STORE_BASIC_SETTINGS_SUCCESS:
      state = { ...state, loading: false };
      break;
    case EDIT_STORE_BASIC_SETTINGS_FAILURE:
      state = { ...state, loading: false };
      break;

    //add store bank account
    case ADD_BANK_ACCOUNT:
      state = { ...state, loading: true };
      break;

    case ADD_BANK_ACCOUNT_SUCCESS:
      state = {
        ...state,
        loading: false,
        // bankAccount: true,
        bankAccounts: [...state.bankAccounts, action?.payload?.bankAccount],
      };
      break;

    case ADD_BANK_ACCOUNT_FAILURE:
      state = { ...state, loading: false };
      break;

    //edit store settings
    case EDIT_STORE_SLUG_SETTING:
      state = { ...state, dataSaveLoading: true };
      break;
    case EDIT_STORE_SLUG_SETTING_SUCCESS:
      state = {
        ...state,
        dataSaveLoading: false,
        settings: {
          ...state?.settings,
          ...(action.payload?.settings || action.payload?.setting),
        },
      };
      break;
    case EDIT_STORE_SLUG_SETTING_FAILURE:
      state = { ...state, dataSaveLoading: false };
      break;

    //edit store bank account
    case EDIT_BANK_ACCOUNT:
      state = { ...state, dataSaveLoading: true };
      break;

    case EDIT_BANK_ACCOUNT_SUCCESS:
      // let settingsTmp = state.settings;

      // if (action?.payload?.bankAccounts) {
      //   settingsTmp.billingSettings.bankAccount =
      //     action?.payload?.bankAccounts?.find((e) => e?.isDefault) ||
      //     action?.payload?.bankAccounts?.[0];

      //   settingsTmp.billingSettings.bankAccount.bank = getId(
      //     settingsTmp.billingSettings?.bankAccount?.bank
      //   );
      // }

      state = {
        ...state,
        dataSaveLoading: false,
        // settings: settingsTmp,
        bankAccount: true,
        bankAccounts: action?.payload?.bankAccounts,
      };
      break;
    case EDIT_BANK_ACCOUNT_FAILURE:
      state = { ...state, dataSaveLoading: false, bankAccount: false };
      break;

    //delete bank account
    case DELETE_BANK_ACCOUNT:
      state = { ...state, dataSaveLoading: true };
      break;

    case DELETE_BANK_ACCOUNT_SUCCESS:
      const data = state?.bankAccounts?.filter(
        (acc) => getId(acc) !== action.payload
      );
      state = {
        ...state,
        dataSaveLoading: false,
        bankAccounts: data,
      };
      break;

    case DELETE_BANK_ACCOUNT_FAILURE:
      state = { ...state, dataSaveLoading: false };
      break;

    //edit store bank account
    case SET_DEFAULT_BANK_ACCOUNT:
      state = { ...state, dataSaveLoading: true };
      break;

    case SET_DEFAULT_BANK_ACCOUNT_SUCCESS:
      // var settingsTmp = state.settings;

      // if (action?.payload?.bankAccounts) {
      //   settingsTmp.billingSettings.bankAccount =
      //     action?.payload?.bankAccounts?.find((e) => e?.isDefault) ||
      //     action?.payload?.bankAccounts?.[0];

      //   settingsTmp.billingSettings.bankAccount.bank = getId(
      //     settingsTmp.billingSettings?.bankAccount?.bank
      //   );
      // }

      state = {
        ...state,
        dataSaveLoading: false,
        // settings: settingsTmp,
        // bankAccount: true,
      };
      break;

    case SET_DEFAULT_BANK_ACCOUNT_FAILURE:
      state = { ...state, dataSaveLoading: false, bankAccount: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default stores;
