import React from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";

import store from "./store";
import App from "./App";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "styles/global.scss";
import "styles/categories.scss";
import "styles/style.scss";
import "styles/MultiLangsInput.scss";
import "styles/customers.scss";
import "styles/orders.scss";
import "styles/product.scss";
import "styles/settings.scss";
import "styles/reports.scss";
// import "styles/responsive.scss";
import Wrapper from "./wrapper";
import { initSocket } from "helpers/socket";

const root = ReactDOM.createRoot(document.getElementById("root"));
initSocket();
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer rtl position="bottom-left" limit={3} />
      <Wrapper>
        <App />
      </Wrapper>
    </Provider>
  </React.StrictMode>
);
