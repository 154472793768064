import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
    editStoreDefaultLanguage,
    editStoreSlugSetting,
    getSettings,
} from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { useIntl } from "react-intl";
import { ReactComponent as LangsIcon } from "assets/svgs/settings/7.svg";
import BreadCrumb from "components/shared/BreadCrumb";

const StoreLangs = () => {
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const [isDefault, setIsDefault] = useState("");
    const dispatch = useDispatch();
    const { settings, dataSaveLoading, loading } = useSelector(
        (state) => state?.settings || {}
    );
    const { languages = [] } = settings;
    const { register, handleSubmit, reset, watch, setValue } = useForm();

    useEffect(() => {
        dispatch(getSettings("languages"));
    }, [dispatch]);
    useEffect(() => {
        if (languages?.length > 0) {
            const activeLanguages = languages.filter((lang) => lang.isActive);
            const currentActiveLanguages = watch("languages") || [];
            const newActiveLanguages = activeLanguages.map((lang) => lang._id);

            if (
                JSON.stringify(currentActiveLanguages) !==
                JSON.stringify(newActiveLanguages)
            ) {
                reset({
                    languages: newActiveLanguages,
                });
            }

            const activeLangIds = activeLanguages.map((lang) => lang._id);
            if (activeLangIds.length === 1) {
                const defaultLang = activeLangIds[0];

                if (isDefault !== defaultLang) {
                    setIsDefault(defaultLang);
                    dispatch(
                        editStoreDefaultLanguage({ languageId: defaultLang })
                    );
                }
            } else {
                const defaultLang =
                    languages.find((lang) => lang.isDefault)?._id || "";
                setIsDefault(defaultLang);
            }
        }
    }, [languages, isDefault, reset, dispatch, watch]);

    const onSubmit = (data) => {
        const selectedLanguages = data.languages || [];

        const activeLanguages = languages
            .map((lang) => ({
                ...lang,
                isActive: selectedLanguages.includes(lang._id),
                isDefault: lang._id === isDefault,
            }))
            .filter((lang) => lang.isActive);

        const formattedData = {
            languages: activeLanguages.map((lang) => ({
                language: lang?._id,
                isDefault: lang?.isDefault,
            })),
        };

        dispatch(
            editStoreSlugSetting({
                data: formattedData,
                slug: "languages",
                navigate,
            })
        );
    };

    if (loading) return <Loader />;

    return (
        <div className="acc-form store-langs">
            {dataSaveLoading && <StickyLoader fill="#FC6B14" />}
            <BreadCrumb
                pageName={formatMessage({ id: "storeLangs" })}
                parent={{
                    text: formatMessage({ id: "settings" }),
                    link: "/settings",
                }}
            />
            <div className="card flex-row justify-content-start align-items-center gap-2 card-head">
                <LangsIcon />
                <h4 className="m-0">اختر لغات المتجر</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Col xl={10} md={12}>
                    <Row className="langs">
                        {languages.map((lang) => (
                            <Col xs="12" lg="4" md="6" key={lang._id}>
                                <div className="lang card">
                                    <p>{lang.name}</p>

                                    <label>
                                        <input
                                            type="radio"
                                            onChange={({ target }) => {
                                                if (
                                                    target.checked &&
                                                    lang.isActive
                                                ) {
                                                    setIsDefault(lang._id);
                                                    dispatch(
                                                        editStoreDefaultLanguage(
                                                            {
                                                                languageId:
                                                                    lang._id,
                                                            }
                                                        )
                                                    );
                                                }
                                            }}
                                            checked={isDefault === lang._id}
                                            value={lang._id}
                                            title={formatMessage({
                                                id: "isDefault",
                                            })}
                                            disabled={!lang.isActive}
                                        />
                                        <p className="active">
                                            {formatMessage({ id: "isDefault" })}
                                        </p>
                                        <p
                                            className="inactive"
                                            style={{
                                                cursor: `${
                                                    lang.isActive
                                                        ? "pointer"
                                                        : "not-allowed"
                                                }`,
                                            }}
                                        >
                                            {formatMessage({
                                                id: "setAsDefault",
                                            })}
                                        </p>
                                    </label>

                                    <label
                                        className="switcher"
                                        title={formatMessage({
                                            id: "addLangToStore",
                                        })}
                                    >
                                        <input
                                            type="checkbox"
                                            className="switcher-input"
                                            {...register("languages")}
                                            onChange={({ target }) => {
                                                const selectedLanguages =
                                                    watch("languages") || [];
                                                if (target.checked) {
                                                    setValue("languages", [
                                                        ...selectedLanguages,
                                                        lang._id,
                                                    ]);
                                                } else {
                                                    setValue(
                                                        "languages",
                                                        selectedLanguages.filter(
                                                            (id) =>
                                                                id !== lang._id
                                                        )
                                                    );
                                                }
                                            }}
                                            value={lang._id}
                                        />
                                        <div className="knobs"></div>
                                        <div className="layer"></div>
                                    </label>
                                </div>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col lg={4} xs={12}>
                            <div className="form-group">
                                <button type="submit" className="btn btn-blue">
                                    حفظ التعديلات
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </form>
        </div>
    );
};

export default StoreLangs;
