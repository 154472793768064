import {
  GET_NEW_NOTIFICATION,
  GET_NEW_NOTIFICATION_FAILURE,
  GET_NEW_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ_FAILURE,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_UNREAD,
  MARK_NOTIFICATION_AS_UNREAD_FAILURE,
  MARK_NOTIFICATION_AS_UNREAD_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
} from "./actionTypes";

const initialState = {
  notifications: [],
  loading: false,
  error: "",
};

const notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_NOTIFICATIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        notifications: action.payload.notifications || [],
      };
      break;
    case GET_NOTIFICATIONS_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // =============================================================================
    case GET_NEW_NOTIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_NEW_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        notifications: [...state.notifications, action.payload.notification],
      };
      break;

    case GET_NEW_NOTIFICATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // =============================================================================
    case UPDATE_NOTIFICATION:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_NOTIFICATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        notifications: state.notifications.map((notification) => {
          if (notification.id !== action.payload.notification.id) {
            return notification;
          }
          return action.payload.notification;
        }),
      };
      break;

    case UPDATE_NOTIFICATION_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;
    // =============================================================================
    case MARK_NOTIFICATION_AS_READ:
      state = {
        ...state,
        loading: true,
      };
      break;

    case MARK_NOTIFICATION_AS_READ_SUCCESS: {
      state = {
        ...state,
        loading: false,
        notifications: state.notifications.map((notification) => {
          if (notification.id !== action.payload.notification.id) {
            return notification;
          }
          return action.payload.notification;
        }),
      };
      break;
    }

    case MARK_NOTIFICATION_AS_READ_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // =============================================================================
    case MARK_NOTIFICATION_AS_UNREAD:
      state = {
        ...state,
        loading: true,
      };
      break;

    case MARK_NOTIFICATION_AS_UNREAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        notifications: state.notifications.map((notification) => {
          if (notification.id !== action.payload.notification.id) {
            return notification;
          }
          return action.payload.notification;
        }),
      };
      break;

    case MARK_NOTIFICATION_AS_UNREAD_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    // =============================================================================
    case MARK_ALL_NOTIFICATIONS_AS_READ:
      state = {
        ...state,
        loading: true,
      };
      break;

    case MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      state = {
        ...state,
        loading: false,
        notifications: state.notifications.map((notification) => {
          return { ...notification, read: true };
        }),
      };
      break;

    case MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE:
      state = { ...state, error: action.payload, loading: false };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default notifications;
