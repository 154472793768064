export const GET_DOMAINS = "GET_DOMAINS";
export const GET_DOMAINS_SUCCESS = "GET_DOMAINS_SUCCESS";
export const GET_DOMAINS_FAILURE = "GET_DOMAINS_FAILURE";

export const GET_SINGLE_DOMAIN = "GET_SINGLE_DOMAIN";
export const GET_SINGLE_DOMAIN_SUCCESS = "GET_SINGLE_DOMAIN_SUCCESS";
export const GET_SINGLE_DOMAIN_FAILURE = "GET_SINGLE_DOMAIN_FAILURE";

export const ADD_DOMAIN = "ADD_DOMAIN";
export const ADD_DOMAIN_SUCCESS = "ADD_DOMAIN_SUCCESS";
export const ADD_DOMAIN_FAILURE = "ADD_DOMAIN_FAILURE";

export const EDIT_DOMAIN = "EDIT_DOMAIN";
export const EDIT_DOMAIN_SUCCESS = "EDIT_DOMAIN_SUCCESS";
export const EDIT_DOMAIN_FAILURE = "EDIT_DOMAIN_FAILURE";

export const DELETE_DOMAIN = "DELETE_DOMAIN";
export const DELETE_DOMAIN_SUCCESS = "DELETE_DOMAIN_SUCCESS";
export const DELETE_DOMAIN_FAILURE = "DELETE_DOMAIN_FAILURE";
