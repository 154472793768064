export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

// =============================================================================
export const GET_NEW_NOTIFICATION = "GET_NEW_NOTIFICATION";
export const GET_NEW_NOTIFICATION_SUCCESS = "GET_NEW_NOTIFICATION_SUCCESS";
export const GET_NEW_NOTIFICATION_FAILURE = "GET_NEW_NOTIFICATION_FAILURE";

// =============================================================================
export const UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

// =============================================================================
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";
export const MARK_NOTIFICATION_AS_READ_SUCCESS =
  "MARK_NOTIFICATION_AS_READ_SUCCESS";
export const MARK_NOTIFICATION_AS_READ_FAILURE =
  "MARK_NOTIFICATION_AS_READ_FAILURE";

// =============================================================================
export const MARK_NOTIFICATION_AS_UNREAD = "MARK_NOTIFICATION_AS_UNREAD";
export const MARK_NOTIFICATION_AS_UNREAD_SUCCESS =
  "MARK_NOTIFICATION_AS_UNREAD_SUCCESS";
export const MARK_NOTIFICATION_AS_UNREAD_FAILURE =
  "MARK_NOTIFICATION_AS_UNREAD_FAILURE";

// =============================================================================
export const MARK_ALL_NOTIFICATIONS_AS_READ = "MARK_ALL_NOTIFICATIONS_AS_READ";
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS =
  "MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS";
export const MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE =
  "MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE";
