import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useForm, useFieldArray } from "react-hook-form";
import { Col, Row } from "react-bootstrap";

import {
    addStore,
    getCategories,
    getPackages,
    getThemeTemplates,
} from "store/actions";
import { useNavigate } from "react-router-dom";
import ImageUpload from "components/shared/ImageUpload";
const AddStore = () => {
    const { locale } = useIntl();
    const [selectedIcon, setSelectedIcon] = useState();
    const [selectedLogo, setSelectedLogo] = useState();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categories } = useSelector((state) => state.categories);
    const { packages } = useSelector((state) => state.packages);

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "branches",
    });

    const onSubmit = (data) => {
        if (selectedLogo && selectedLogo.path) data["logo"] = selectedLogo.path;
        if (selectedIcon && selectedIcon.path)
            data["favIcon"] = selectedIcon.path;
        dispatch(addStore({ data, navigate }));
    };

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getPackages());
        dispatch(getThemeTemplates());
    }, [dispatch]);

    // useEffect(() => {
    //   register("themes", { required: true });
    // }, []);

    // const imageChange = async (e) => {
    //   if (e.target.files && e.target.files.length > 0) {
    //     const formData = new FormData();
    //     formData.append("file", e.target.files[0]);
    //     const { data } = await server().post("/upload", formData);
    //     setSelectedImage({
    //       preview: e.target.files[0],
    //       path: getPublicImage(data.data.files[0]),
    //     });
    //   }
    // };
    // const removeSelectedImage = () => {
    //   setSelectedImage();
    // };

    const renderCategoriesSelectOptions = () => {
        return categories.map((category) => {
            return (
                <option value={category.id} key={category.id}>
                    {category?.name?.[locale]}
                </option>
            );
        });
    };

    const renderPackagesSelectOptions = () => {
        return packages.map((packagee) => {
            return (
                <option value={packagee.id} key={packagee.id}>
                    {packagee?.name?.[locale]}
                </option>
            );
        });
    };

    // const renderListThemes = () => {
    //   return themes?.map((theme) => ({
    //     label: theme.name?.[locale],
    //     value: theme?.name?.[locale],
    //     id: theme?.id,
    //     theme: theme?.id,
    //     image: theme?.image,
    //   }));
    // };

    return (
        <>
            <div className="acc-form">
                <div className="card">
                    <div className="card-head">
                        <h4>اضف متجر جديد</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Col xl={10} md={12}>
                                <Row>
                                    <Row>
                                        <Col sm={6}>
                                            <div className="form-group required">
                                                <h5>صورة المتجر</h5>
                                                {/* <div className="image-uploader image-contain">
                        <div className="img-pat">
                          <img
                            src={imageUpload}
                            width={46}
                            height={46}
                            alt=""
                          />
                        </div>
                        <label>
                          {selectedImage && (
                            <div>
                              <img
                                src={
                                  selectedImage &&
                                  selectedImage.preview &&
                                  URL.createObjectURL(selectedImage.preview)
                                }
                                width={160}
                                height={160}
                                alt=""
                              />
                              <button
                                className="removeImg"
                                onClick={removeSelectedImage}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  fill="currentColor"
                                  className="bi bi-x-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            type="file"
                            onChange={imageChange}
                          />
                        </label>

                        <p className="error-hint">
                          {errors.logo?.type === "required" &&
                            "يرجي اختيار صورة المتجر"}
                        </p>
                      </div> */}
                                                <ImageUpload
                                                    className="m-1"
                                                    selectedImage={selectedLogo}
                                                    setSelectedImage={
                                                        setSelectedLogo
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="form-group required">
                                                <h5>ايقونة المتجر</h5>
                                                <ImageUpload
                                                    className="m-1"
                                                    selectedImage={selectedIcon}
                                                    setSelectedImage={
                                                        setSelectedIcon
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>التصنيفات</h5>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    {...register("category", {
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">
                                                        اختر التصنيف
                                                    </option>
                                                    {renderCategoriesSelectOptions()}
                                                </select>
                                            </div>
                                            <p className="error-hint">
                                                {errors.category?.type ===
                                                    "required" &&
                                                    "يرجي اختيار التصنيف"}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>الباقات</h5>
                                            <div>
                                                <select
                                                    className="form-control"
                                                    {...register("pkg", {
                                                        required: true,
                                                    })}
                                                >
                                                    <option value="">
                                                        اختر الباقة
                                                    </option>
                                                    {renderPackagesSelectOptions()}
                                                </select>
                                            </div>
                                            <p className="error-hint">
                                                {errors.package?.type ===
                                                    "required" &&
                                                    "يرجي اختيار باقة"}
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>إسم المتجر بالعربية</h5>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-outline"
                                                    placeholder="إسم المتجر بالعربية"
                                                    {...register("name[ar]", {
                                                        required: true,
                                                    })}
                                                />
                                                <input
                                                    type="hidden"
                                                    className="form-control form-outline"
                                                    placeholder="الصورة"
                                                    value="hhhhh"
                                                    {...register("image", {
                                                        required: true,
                                                    })}
                                                />
                                            </div>
                                            <p className="error-hint">
                                                {errors?.name?.["ar"]?.type ===
                                                    "required" &&
                                                    "يرجي ادخال اسم المتجر بالعربية"}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>إسم المتجر بالإنجليزية</h5>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-outline"
                                                    placeholder="إسم المتجر بالإنجليزية"
                                                    {...register("name[en]", {
                                                        required: true,
                                                    })}
                                                />
                                            </div>
                                            <p className="error-hint">
                                                {errors?.name?.["en"]?.type ===
                                                    "required" &&
                                                    "يرجي ادخال اسم المتجر بالإنجليزية"}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>رقم الجوال</h5>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-outline"
                                                    placeholder="رقم الجوال"
                                                    {...register("phone", {
                                                        required: true,
                                                    })}
                                                />
                                            </div>
                                            <p className="error-hint">
                                                {errors.phone?.type ===
                                                    "required" &&
                                                    "يرجي ادخال رقم الجوال"}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>اسم النطاق</h5>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-outline"
                                                    placeholder="اسم النطاق"
                                                    {...register("domain", {
                                                        required: true,
                                                    })}
                                                />
                                            </div>
                                            <p className="error-hint">
                                                {errors.domain?.type ===
                                                    "required" &&
                                                    "يرجي ادخال اسم النطاق"}
                                            </p>
                                        </div>
                                    </Col>

                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>وصف المتجر بالعربية</h5>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-outline"
                                                    placeholder="وصف المتجر بالعربية"
                                                    {...register(
                                                        "description[ar]",
                                                        { required: true }
                                                    )}
                                                />
                                            </div>
                                            <p className="error-hint">
                                                {errors?.description?.["ar"]
                                                    ?.type === "required" &&
                                                    "يرجي ادخال وصف المتجر بالعربية"}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col lg={6} xs={12}>
                                        <div className="form-group required">
                                            <h5>وصف المتجر بالإنجليزية</h5>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="form-control form-outline"
                                                    placeholder="وصف المتجر بالإنجليزية"
                                                    {...register(
                                                        "description[en]",
                                                        { required: true }
                                                    )}
                                                />
                                            </div>
                                            <p className="error-hint">
                                                {errors?.description?.["en"]
                                                    ?.type === "required" &&
                                                    "يرجي ادخال وصف المتجر بالإنجليزية"}
                                            </p>
                                        </div>
                                    </Col>

                                    {/* <Col lg={12} xs={12}>
                    <div className="form-group required">
                      <h5>الثيمات</h5>
                      <div>
                        <Select
                          isRtl={true}
                          isSearchable={false}
                          className="basic-single"
                          classNamePrefix="select"
                          options={renderListThemes()}
                          placeholder="اختار الثيمات"
                          isMulti={true}
                          onChange={(themes) => {
                            setValue("themes", [
                              ...themes?.map((theme) => ({
                                theme: theme?.id,
                                isActive: false,
                              })),
                            ]);
                          }}
                        />
                      </div>
                    </div>
                  </Col> */}

                                    <Col lg={12}>
                                        <div className="form-group branches">
                                            <h5>الفروع</h5>

                                            {fields.map((item, index) => {
                                                return (
                                                    <div
                                                        key={item.id}
                                                        className="add-multi-component"
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={() =>
                                                                remove(index)
                                                            }
                                                            className="removeImg"
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="12"
                                                                height="12"
                                                                fill="currentColor"
                                                                className="bi bi-x-lg"
                                                                viewBox="0 0 16 16"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                                                                />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <Row>
                                                            <Col lg={6} xs={12}>
                                                                <div className="form-group">
                                                                    <h5>
                                                                        اسم
                                                                        الفرع
                                                                        بالعربية
                                                                    </h5>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-outline"
                                                                        placeholder="اسم الفرع بالعربية"
                                                                        {...register(
                                                                            `branches.${index}.name[ar]`,
                                                                            {
                                                                                required: true,
                                                                            }
                                                                        )}
                                                                    />
                                                                    <p className="error-hint">
                                                                        {errors
                                                                            .branches
                                                                            ?.name?.[
                                                                            "ar"
                                                                        ]
                                                                            ?.type ===
                                                                            "required" &&
                                                                            "يرجي ادخال اسم الفرع بالعربية"}
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} xs={12}>
                                                                <div className="form-group">
                                                                    <h5>
                                                                        اسم
                                                                        الفرع
                                                                        بالإنجليزية
                                                                    </h5>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-outline"
                                                                        placeholder="اسم الفرع بالإنجليزية"
                                                                        {...register(
                                                                            `branches.${index}.name[en]`,
                                                                            {
                                                                                required: true,
                                                                            }
                                                                        )}
                                                                    />
                                                                    <p className="error-hint">
                                                                        {errors
                                                                            .branches
                                                                            ?.name?.[
                                                                            "en"
                                                                        ]
                                                                            ?.type ===
                                                                            "required" &&
                                                                            "يرجي ادخال اسم الفرع بالإنجليزية"}
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} xs={12}>
                                                                <div className="form-group">
                                                                    <h5>
                                                                        رقم
                                                                        الجوال
                                                                    </h5>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-outline"
                                                                        placeholder="رقم الجوال"
                                                                        {...register(
                                                                            `branches.${index}.phone`,
                                                                            {
                                                                                required: true,
                                                                            }
                                                                        )}
                                                                    />
                                                                    <p className="error-hint">
                                                                        {errors
                                                                            .branches
                                                                            ?.phone
                                                                            ?.type ===
                                                                            "required" &&
                                                                            "يرجي ادخال رقم الجوال"}
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                            <Col lg={6} xs={12}>
                                                                <div className="form-group">
                                                                    <h5>
                                                                        عنوان
                                                                        الفرع
                                                                    </h5>

                                                                    <input
                                                                        type="text"
                                                                        className="form-control form-outline"
                                                                        placeholder="عنوان الفرع"
                                                                        {...register(
                                                                            `branches.${index}.address[addressDetail]`,
                                                                            {
                                                                                required: true,
                                                                            }
                                                                        )}
                                                                    />

                                                                    <p className="error-hint">
                                                                        {errors
                                                                            .branches
                                                                            ?.addressDetail?.[
                                                                            "addressDetail"
                                                                        ]
                                                                            ?.type ===
                                                                            "required" &&
                                                                            "يرجي ادخال عنوان الفرع"}
                                                                    </p>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                );
                                            })}
                                            <Col
                                                lg={2}
                                                xs={12}
                                                className="text-left"
                                            >
                                                <button
                                                    type="button"
                                                    className="btn btn-green"
                                                    onClick={() => {
                                                        append({});
                                                    }}
                                                >
                                                    إضافة فرع
                                                </button>
                                            </Col>
                                        </div>
                                    </Col>
                                    <input
                                        type="hidden"
                                        {...register("logo")}
                                    />

                                    <Col lg={4} xs={12}>
                                        <div className="form-group">
                                            <button
                                                type="submit"
                                                className="btn btn-blue"
                                            >
                                                اضافة المتجر
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddStore;
