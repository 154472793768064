import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  getNotifications,
  markAllNotificationsAsRead,
  markNotificationAsRead,
  markNotificationAsUnread,
} from "store/actions";

import arrowIcon from "../../assets/svgs/arrow-left.svg";
import { ReactComponent as NotificationIcon } from "../../assets/svgs/notification.svg";

import createdIcon from "../../assets/svgs/order-status/created.svg";
import shippedIcon from "../../assets/svgs/order-status/shipped.svg";
import deletedIcon from "../../assets/svgs/order-status/deleted.svg";
import updatedIcon from "../../assets/svgs/order-status/updated.svg";
import priortyIcon from "../../assets/svgs/order-status/priorty.svg";
import calendarIcon from "../../assets/svgs/order-status/calendar.svg";
import userIcon from "../../assets/svgs/order-status/profile.svg";

import TimeInst from "./TimeInst";
import { FormattedMessage } from "react-intl";
import { getId } from "helpers/functions";

const Notifications = () => {
  const dispatch = useDispatch();

  const [realTimeNotifs] = useState([]);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  const { notifications } = useSelector((state) => state.notifications);

  const notifsData = [...realTimeNotifs, ...notifications]?.sort(
    (a, b) =>
      new Date(b?.createdAt)?.getTime() - new Date(a?.createdAt)?.getTime()
  );

  const unreadNotifsLength = notifsData?.filter(
    (notif) => notif?.read === false
  ).length;

  const checkNotificationType = (type) => {
    switch (type) {
      case "order-created":
        return createdIcon;
      case "order-shipped":
        return shippedIcon;
      case "order-deleted":
        return deletedIcon;
      case "order-updated":
        return updatedIcon;
      case "user-logged-in":
        return userIcon;
      default:
        return createdIcon;
    }
  };

  const renderNotifications = notifications?.map((notification, index) => {
    return (
      <Link
        to={
          notification?.type === "order-created" ||
          notification?.type === "order-updated" ||
          notification?.type === "order-deleted" ||
          notification?.type === "order-shipped"
            ? `/orders/show/${notification?.data?.orderId}`
            : notification?.type === "user-logged-in"
            ? "/"
            : "/"
        }
        key={index}
        data-notification-read={notification?.read}
      >
        <div
          className="i-img"
          style={
            notification?.type === "order-created"
              ? { backgroundColor: "#0A3370" }
              : notification?.type === "order-updated"
              ? { backgroundColor: "#180A70" }
              : notification?.type === "order-deleted"
              ? { backgroundColor: "#700A0A" }
              : notification?.type === "order-shipped"
              ? { backgroundColor: "#24700A" }
              : notification?.type === "user-logged-in"
              ? { backgroundColor: "#24700A" }
              : { backgroundColor: "#0A3370" }
          }
        >
          <img src={checkNotificationType(notification?.type)} alt="" />
        </div>
        <div className="i-data">
          <div>
            <h5>
              {notification?.title}

              <OverlayTrigger
                overlay={
                  <Tooltip placement="top">
                    <FormattedMessage id="priority" /> :{" "}
                    {notification?.priority}
                  </Tooltip>
                }
              >
                <i
                  className="priority"
                  style={
                    notification?.priority === "high"
                      ? { backgroundColor: "#FFB800" }
                      : notification?.priority === "normal"
                      ? { backgroundColor: "#0085FF" }
                      : notification?.priority === "low"
                      ? { backgroundColor: "#F00" }
                      : { backgroundColor: "#0085FF" }
                  }
                >
                  <img src={priortyIcon} alt="" />
                </i>
              </OverlayTrigger>
            </h5>
            {(notification?.type === "order-created" ||
              notification?.type === "order-updated" ||
              notification?.type === "order-deleted" ||
              notification?.type === "order-shipped") && (
              <p>
                <FormattedMessage id="orderWithTrackingNumber" />{" "}
                <b>{notification?.data?.trackingNumber}</b>{" "}
                <FormattedMessage id="hasBeenCreated" />
              </p>
            )}
            <div className="not-footer">
              {(notification?.type === "order-created" ||
                notification?.type === "order-updated" ||
                notification?.type === "order-deleted" ||
                notification?.type === "order-shipped") && (
                <span>
                  <FormattedMessage id="carrier" />:{" "}
                  {notification?.data?.carrier}
                </span>
              )}

              <strong>
                <img src={calendarIcon} alt="" />
                <TimeInst date={new Date(notification?.createdAt)} />
              </strong>
            </div>
          </div>
          {!notification?.read ? (
            <OverlayTrigger
              overlay={
                <Tooltip placement="top">
                  <FormattedMessage id="markAsRead" />
                </Tooltip>
              }
            >
              <button
                type="button"
                className="mark-as-read"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(markNotificationAsRead(getId(notification)));
                }}
              ></button>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              overlay={
                <Tooltip placement="top">
                  <FormattedMessage id="markAsUnread" />
                </Tooltip>
              }
            >
              <button
                type="button"
                className="mark-as-read"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(markNotificationAsUnread(getId(notification)));
                }}
              ></button>
            </OverlayTrigger>
          )}
        </div>
      </Link>
    );
  });

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);

  return (
    <div className="noificationWrap">
      <ul>
        <li className="menu-item-has-children">
          <Dropdown
          //  onToggle={readNotifs}
          >
            <Dropdown.Toggle>
              <span>
                <NotificationIcon fill="" />
                <i className="badgo">{unreadNotifsLength}</i>
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="notification-head">
                <span>التنبيهات</span>
                {notifications?.filter((notif) => notif?.read === false)
                  .length > 0 && (
                  <button
                    type="button"
                    className="mark-all"
                    onClick={() => {
                      dispatch(markAllNotificationsAsRead());
                    }}
                  >
                    <FormattedMessage id="markAllAsRead" />
                  </button>
                )}
              </div>
              {notifications?.length > 0 ? (
                <ul>{renderNotifications}</ul>
              ) : (
                <div className="no-notifications">
                  <FormattedMessage id="noNotifications" />
                </div>
              )}
              <div className="notification-footer">
                <Link to="/notifications">
                  رؤيـة الـمزيد <img src={arrowIcon} alt="" />
                </Link>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>
    </div>
  );
};

export default Notifications;
