import server from "./server";

export const getDomainsApi = async (payload) => {
    const response = await server().get(`/stores/domains`);
    return response.data;
};

export const getSingleDomainApi = async (id) => {
    const response = await server().get(`/stores/domains/${id}`);
    return response.data;
};

export const addDomainApi = async (domain) => {
    const response = await server().post("/stores/domains", domain);
    return response.data;
};

export const editDomainApi = async ({ id, domain }) => {
    const response = await server().put(`/stores/domains/${id}`, { domain });
    return response.data;
};

export const deleteDomainApi = async (id) => {
    const response = await server().delete(`/stores/domains/${id}`);
    return response.data;
};
