import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { editStoreSlugSetting, getSettings } from "store/actions";
import Loader from "components/shared/Loader";
import { useNavigate } from "react-router-dom";
import StickyLoader from "components/shared/StickyLoader";
import { Input, Textarea } from "components/shared/FormComponents";

const StoreMaintenance = () => {
    const navigate = useNavigate();

    const { settings, dataSaveLoading, loading } =
        useSelector((state) => state?.settings) || {};

    const dispatch = useDispatch();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatch(getSettings("maintenance"));
    }, [dispatch]);

    const maintenance = settings?.maintenance;

    useEffect(() => {
        reset({
            title: maintenance?.title,
            description: maintenance?.description,
            isActive: !!maintenance?.isActive,
        });
    }, [reset, maintenance]);

    const onSubmit = (data) => {
        dispatch(
            editStoreSlugSetting({
                data: { maintenance: data },
                slug: "maintenance",
                navigate,
            })
        );
    };

    if (loading) return <Loader />;

    return (
        <div className="acc-form card">
            {dataSaveLoading && <StickyLoader fill="#FC6B14" />}

            <div className="card-head">
                <h4>وضع صيانة المتجر</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col sm="6" lg="4" xs="12">
                        <div className="form-group">
                            <h5>تفعيل الوضع</h5>
                            <div>
                                <label className="switcher">
                                    <input
                                        type="checkbox"
                                        name="isActive"
                                        className="switcher-input"
                                        {...register("isActive")}
                                    />
                                    <div className="knobs"></div>
                                    <div className="layer"></div>
                                </label>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Col xl={10} md={12}>
                    <Row>
                        <Col lg={8} xs={12}>
                            <div className="form-group">
                                <h5>العنوان</h5>
                                <div>
                                    <Input
                                        name="title"
                                        register={register}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={8} xs={12}>
                            <div className="form-group">
                                <h5>الوصف</h5>
                                <Textarea
                                    name="description"
                                    register={register}
                                    errors={errors}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} xs={12}>
                            <div className="form-group">
                                <button type="submit" className="btn btn-blue">
                                    حفظ التعديلات
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </form>
        </div>
    );
};

export default StoreMaintenance;
