import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import {
  getNewNotificationFailure,
  getNewNotificationSuccess,
  getNotificationsFailure,
  getNotificationsSuccess,
  markAllNotificationsAsReadFailure,
  markAllNotificationsAsReadSuccess,
  markNotificationAsReadFailure,
  markNotificationAsReadSuccess,
  markNotificationAsUnreadFailure,
  markNotificationAsUnreadSuccess,
  updateNotificationFailure,
  updateNotificationSuccess,
} from "./actions";

import {
  GET_NEW_NOTIFICATION,
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_UNREAD,
  UPDATE_NOTIFICATION,
} from "./actionTypes";
import {
  getNotificationsApi,
  markAllNotificationsAsReadApi,
  markNotificationAsReadApi,
  markNotificationAsUnreadApi,
} from "api/notifications";

function* getNotifications() {
  try {
    const { data } = yield call(getNotificationsApi);
    yield put(getNotificationsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getNotificationsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* getNewNotification({ payload }) {
  if (!payload) return;
  try {
    yield put(getNewNotificationSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(
      getNewNotificationFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* updateNotification({ payload }) {
  if (!payload) return;
  try {
    yield put(updateNotificationSuccess(payload));
  } catch (error) {
    console.log(error);
    yield put(
      updateNotificationFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* markNotificationAsRead({ payload }) {
  try {
    const { data } = yield call(markNotificationAsReadApi, payload);
    yield put(markNotificationAsReadSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      markNotificationAsReadFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* markNotificationAsUnread({ payload }) {
  const { data } = yield call(markNotificationAsUnreadApi, payload);
  try {
    yield put(markNotificationAsUnreadSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      markNotificationAsUnreadFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

function* markAllNotificationsAsRead() {
  try {
    const { data } = yield call(markAllNotificationsAsReadApi);
    yield put(markAllNotificationsAsReadSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(
      markAllNotificationsAsReadFailure(error?.response?.data?.errors?.[0]?.msg)
    );
  }
}

export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, getNotifications);
}

export function* watchGetNewNotification() {
  yield takeEvery(GET_NEW_NOTIFICATION, getNewNotification);
}

export function* watchUpdateNotification() {
  yield takeEvery(UPDATE_NOTIFICATION, updateNotification);
}

export function* watchMarkNotificationAsRead() {
  yield takeEvery(MARK_NOTIFICATION_AS_READ, markNotificationAsRead);
}

export function* watchMarkNotificationAsUnread() {
  yield takeEvery(MARK_NOTIFICATION_AS_UNREAD, markNotificationAsUnread);
}

export function* watchMarkAllNotificationsAsRead() {
  yield takeEvery(MARK_ALL_NOTIFICATIONS_AS_READ, markAllNotificationsAsRead);
}

function* notificationsSaga() {
  yield all([fork(watchGetNotifications)]);
  yield all([fork(watchGetNewNotification)]);
  yield all([fork(watchUpdateNotification)]);
  yield all([fork(watchMarkNotificationAsRead)]);
  yield all([fork(watchMarkNotificationAsUnread)]);
}

export default notificationsSaga;
