import {
  GET_NEW_NOTIFICATION,
  GET_NEW_NOTIFICATION_FAILURE,
  GET_NEW_NOTIFICATION_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_READ_FAILURE,
  MARK_NOTIFICATION_AS_READ_SUCCESS,
  MARK_NOTIFICATION_AS_UNREAD,
  MARK_NOTIFICATION_AS_UNREAD_FAILURE,
  MARK_NOTIFICATION_AS_UNREAD_SUCCESS,
  UPDATE_NOTIFICATION,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
} from "./actionTypes";

export const getNotifications = () => {
  return {
    type: GET_NOTIFICATIONS,
  };
};

export const getNotificationsSuccess = (notifications) => {
  return {
    type: GET_NOTIFICATIONS_SUCCESS,
    payload: notifications,
  };
};

export const getNotificationsFailure = (error) => {
  return {
    type: GET_NOTIFICATIONS_FAILURE,
    payload: error,
  };
};

// =============================================================================
export const getNewNotification = (notification) => {
  return {
    type: GET_NEW_NOTIFICATION,
    payload: notification,
  };
};

export const getNewNotificationSuccess = (notification) => {
  return {
    type: GET_NEW_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const getNewNotificationFailure = (error) => {
  return {
    type: GET_NEW_NOTIFICATION_FAILURE,
    payload: error,
  };
};

// =============================================================================
export const updateNotification = (notification) => {
  return {
    type: UPDATE_NOTIFICATION,
    payload: notification,
  };
};

export const updateNotificationSuccess = (notification) => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    payload: notification,
  };
};

export const updateNotificationFailure = (error) => {
  return {
    type: UPDATE_NOTIFICATION_FAILURE,
    payload: error,
  };
};

// =============================================================================
export const markNotificationAsRead = (id) => {
  return {
    type: MARK_NOTIFICATION_AS_READ,
    payload: id,
  };
};

export const markNotificationAsReadSuccess = (id) => {
  return {
    type: MARK_NOTIFICATION_AS_READ_SUCCESS,
    payload: id,
  };
};

export const markNotificationAsReadFailure = (error) => {
  return {
    type: MARK_NOTIFICATION_AS_READ_FAILURE,
    payload: error,
  };
};

// =============================================================================
export const markNotificationAsUnread = (payload) => {
  return {
    type: MARK_NOTIFICATION_AS_UNREAD,
    payload,
  };
};

export const markNotificationAsUnreadSuccess = (payload) => {
  return {
    type: MARK_NOTIFICATION_AS_UNREAD_SUCCESS,
    payload,
  };
};

export const markNotificationAsUnreadFailure = (error) => {
  return {
    type: MARK_NOTIFICATION_AS_UNREAD_FAILURE,
    payload: error,
  };
};

// =============================================================================
export const markAllNotificationsAsRead = (payload) => {
  return {
    type: MARK_ALL_NOTIFICATIONS_AS_READ,
  };
};

export const markAllNotificationsAsReadSuccess = () => {
  return {
    type: MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
  };
};

export const markAllNotificationsAsReadFailure = (error) => {
  return {
    type: MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE,
    payload: error,
  };
};
