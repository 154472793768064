import { getId } from "helpers/functions";
import {
    ADD_DOMAIN,
    ADD_DOMAIN_FAILURE,
    ADD_DOMAIN_SUCCESS,
    DELETE_DOMAIN,
    DELETE_DOMAIN_FAILURE,
    DELETE_DOMAIN_SUCCESS,
    EDIT_DOMAIN,
    EDIT_DOMAIN_FAILURE,
    EDIT_DOMAIN_SUCCESS,
    GET_DOMAINS,
    GET_DOMAINS_FAILURE,
    GET_DOMAINS_SUCCESS,
    GET_SINGLE_DOMAIN,
    GET_SINGLE_DOMAIN_FAILURE,
    GET_SINGLE_DOMAIN_SUCCESS,
} from "./actionTypes";

const initialState = {
    domains: [],
    loading: false,
    error: "",
    singleDomain: {},
};

const stores = (state = initialState, action) => {
    switch (action.type) {
        case GET_DOMAINS:
            state = {
                ...state,
                loading: true,
            };
            break;
        case GET_DOMAINS_SUCCESS:
            state = {
                ...state,
                loading: false,
                domains: action.payload.domains,
            };
            break;
        case GET_DOMAINS_FAILURE:
            state = { ...state, error: action.payload, loading: false };
            break;

        case GET_SINGLE_DOMAIN:
            state = {
                ...state,
                loading: true,
            };
            break;
        case GET_SINGLE_DOMAIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                singleDomain: action.payload.domain,
            };
            break;
        case GET_SINGLE_DOMAIN_FAILURE:
            state = {
                ...state,
                error: action.payload,
                loading: false,
                singleDomain: {},
            };
            break;

        case ADD_DOMAIN:
            state = {
                ...state,
                loading: true,
            };
            break;
        case ADD_DOMAIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                domains: [...state.domains, action.payload.storedomain],
            };
            break;
        case ADD_DOMAIN_FAILURE:
            state = { ...state, error: action.payload, loading: false };
            break;

        case EDIT_DOMAIN:
            state = {
                ...state,
                loading: true,
            };
            break;
        case EDIT_DOMAIN_SUCCESS:
            const domainsAfterUpdate = [
                ...state.domains.filter(
                    (domain) => domain.id !== action.payload.storeDomain.id
                ),
            ];
            state = {
                ...state,
                loading: false,
                domains: [...domainsAfterUpdate, action.payload.storeDomain],
            };
            break;
        case EDIT_DOMAIN_FAILURE:
            state = { ...state, error: action.payload, loading: false };
            break;

        case DELETE_DOMAIN:
            state = {
                ...state,
                loading: true,
            };
            break;
        case DELETE_DOMAIN_SUCCESS:
            const domainsAfterDeleting = [
                ...state?.domains?.filter(
                    (domain) => getId(domain) !== action.payload
                ),
            ];
            state = {
                ...state,
                loading: false,
                domains: domainsAfterDeleting,
            };
            break;
        case DELETE_DOMAIN_FAILURE:
            state = {
                ...state,
                loading: false,
                error: action.payload,
            };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default stores;
