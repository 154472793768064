/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCities,
  getCountries,
  getDomains,
  getProductCategories,
  getProducts,
} from "store/actions";
import Loader from "components/shared/Loader";
import { useIntl } from "react-intl";
import BreadCrumb from "components/shared/BreadCrumb";
import Table from "./Table";
import ControlArea from "components/Layout/ControlArea";
import DomainModal from "./DomainModal";

const StoreDomainTable = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [modalType, setModalType] = useState("add");
  const [show, setShow] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState({});

  const { domains, loading } = useSelector((state) => state?.domains) || {};

  const handleShowDomainModal = (domain) => {
    if (domain) {
      setModalType("edit");
      setSelectedDomain(domain);
    } else {
      setModalType("add");
    }
    setShow(true);
  };
  const handleCloseDomainModal = () => {
    setShow(false);
    setSelectedDomain("");
  };

  useEffect(() => {
    dispatch(getDomains());
    dispatch(getCountries());
    dispatch(getCities());
    dispatch(getProducts());
    dispatch(getProductCategories());
  }, [dispatch]);

  if (loading) return <Loader />;

  return (
    <div className="store-settings-page">
      <BreadCrumb
        pageName={formatMessage({
          id: "storeDomain",
        })}
        parent={{
          text: formatMessage({ id: "settings" }),
          link: "/settings",
        }}
      />
      <ControlArea
        btnTxt="إضافة دومين"
        cardTxt="دومين المتجر"
        handleClick={() => {
          handleShowDomainModal();
        }}
      />

      <Table
        handleShow={handleShowDomainModal}
        setSelectedDomain={setSelectedDomain}
        data={domains}
      />
      <DomainModal
        show={show}
        setShow={setShow}
        handleClose={handleCloseDomainModal}
        handleShow={handleShowDomainModal}
        selectedDomain={selectedDomain}
        modalType={modalType}
      />
    </div>
  );
};

export default StoreDomainTable;
