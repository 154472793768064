import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Import Api files
import {
  addDomainApi,
  deleteDomainApi,
  editDomainApi,
  getDomainsApi,
  getSingleDomainApi,
} from "api/domains";

// Login Redux States
import {
  ADD_DOMAIN,
  DELETE_DOMAIN,
  EDIT_DOMAIN,
  GET_DOMAINS,
  GET_SINGLE_DOMAIN,
} from "./actionTypes";
import {
  addDomainFailure,
  addDomainSuccess,
  deleteDomainFailure,
  deleteDomainSuccess,
  editDomainFailure,
  editDomainSuccess,
  getDomainsFailure,
  getDomainsSuccess,
  getSingleDomainFailure,
  getSingleDomainsuccess,
} from "./actions";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";

function* getDomains({ payload }) {
  try {
    const { data } = yield call(getDomainsApi, payload);
    yield put(getDomainsSuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });
    console.log(error);
    yield put(getDomainsFailure(error?.response?.data?.errors?.[0]?.msg));
  }
}

function* addDomain({ payload }) {
  try {
    const { data, success } = yield call(addDomainApi, payload);
    yield put(addDomainSuccess(data));
    if (success) {
      yield payload.toastSuccessMessage({
        msg: "Successfully added domain",
      });
      yield payload.handleClose();
    }
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data, autoClose: 6000 });

    console.log("error", error);
    yield put(addDomainFailure({ ...error?.response?.data }));
  }
}

function* getSingleDomain({ payload }) {
  try {
    const { data } = yield call(getSingleDomainApi, payload);
    yield put(getSingleDomainsuccess(data));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });

    console.log(error);
    yield put(getSingleDomainFailure(error?.response?.data || error));
  }
}

function* editDomain({ payload }) {
  try {
    const { data, success } = yield call(editDomainApi, payload);
    if (success) {
      yield put(editDomainSuccess(data));
      yield payload.toastSuccessMessage({
        msg: "Successfully Updated domain",
      });
      yield payload.handleClose();
    }
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data, autoClose: 6000 });

    console.log(
      JSON.parse(JSON.stringify(error?.response?.data?.errors?.[0]?.msg))
    );
    yield put(editDomainFailure(error?.response?.data || error));
  }
}

function* deleteDomain({ payload }) {
  try {
    yield call(deleteDomainApi, payload);
    yield put(deleteDomainSuccess(payload));
  } catch (error) {
    toastErrorMessage({ error: error?.response?.data });

    console.log(error);
    yield put(deleteDomainFailure(error?.response?.data || error));
  }
}

export function* watchGetDomains() {
  yield takeEvery(GET_DOMAINS, getDomains);
}

export function* watchAddDomain() {
  yield takeEvery(ADD_DOMAIN, addDomain);
}

export function* watchGetSingleDomain() {
  yield takeEvery(GET_SINGLE_DOMAIN, getSingleDomain);
}

export function* watchEditDomain() {
  yield takeEvery(EDIT_DOMAIN, editDomain);
}

export function* watchDeleteDomain() {
  yield takeEvery(DELETE_DOMAIN, deleteDomain);
}

function* domainsSaga() {
  yield all([fork(watchGetDomains)]);
  yield all([fork(watchAddDomain)]);
  yield all([fork(watchGetSingleDomain)]);
  yield all([fork(watchEditDomain)]);
  yield all([fork(watchDeleteDomain)]);
}

export default domainsSaga;
