import { REACT_APP_URL } from "config";
import { io } from "socket.io-client";
import store from "store";
import {
  getNewNotification,
  getNotifications,
  updateNotification,
} from "store/actions";

const URL = REACT_APP_URL;

export const initSocket = () => {
  const socket = io(URL, {
    extraHeaders: {
      Authorization: localStorage.getItem("token"),
      store: localStorage.getItem("store"),
    },
  });

  socket.on("notification-created", (notification) => {
    store.dispatch(getNewNotification(notification));
  });

  socket.on("notification-updated", (notification) => {
    store.dispatch(updateNotification(notification));
  });

  socket.on("notification-list", () => {
    store.dispatch(getNotifications());
  });
};
