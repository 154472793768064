import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router";

import { getCurrentUser } from "store/actions";
import Loader from "./components/shared/Loader";

import GuestRoute from "helpers/authentication/guestRoute";
import AuthRoute from "helpers/authentication/authRoute";

import Layout from "./components/Layout/Layout";
import Dashboard from "views/dashboard";
import NotFound from "views/NotFound";
import Login from "views/login";
import Logout from "views/Logout";

import StoresIndex from "views/stores/Index";
import AddStore from "views/stores/AddStore";
import EditStore from "views/stores/EditStore";
import ShowStore from "views/stores/ShowStore";

// import UsersIndex from "views/users/Index";
// import AddUser from "views/users/AddUser";
// import EditUser from "views/users/EditUser";

import CategoriesIndex from "views/categories/Index";
import AddCategory from "views/categories/AddCategory";
import EditCategory from "views/categories/EditCategory";

import ProductsIndex from "views/products/Index";
import ProductModal from "views/products/ProductModal";

import BrandsIndex from "views/brands/Index";
import AddBrand from "views/brands/AddBrand";
import EditBrand from "views/brands/EditBrand";

import BlogsIndex from "views/blogs/Index";
import AddBlog from "views/blogs/AddBlog";
import EditBlog from "views/blogs/EditBlog";
import ShowBlog from "views/blogs/ShowBlog";

import BlogCategoriesIndex from "views/blogsCategories/Index";
import AddBlogCategory from "views/blogsCategories/AddBlogCategory";
import EditBlogCategory from "views/blogsCategories/EditBlogCategory";

import StoreThemesIndex from "views/storeThemes/Index";
import ThemeTemplateIndex from "views/themeTemplates/Index";
import Edit from "views/storeThemes/Edit";

import OrdersIndex from "views/orders/Index";
import ShowOrder from "views/orders/ShowOrder";

import Customers from "views/customers/Index";

import Settings from "views/settings/index";
import BasicSettings from "views/settings/basicSettings";
import StoreCurrencies from "views/settings/StoreCurrencies";
import StoreLangs from "views/settings/StoreLangs";
import StorePayments from "views/settings/StoreWallet";
import StoreSeo from "views/settings/StoreSeo";
import StoreMaintenance from "views/settings/StoreMaintenance";

import CouponsIndex from "views/coupons/Index";

import Pages from "views/pages/index";
import EditPage from "views/pages/EditPage";
import AddPage from "views/pages/AddPage";

import Profile from "views/Profile";
import VisitsReports from "views/visits-reports";
import EditPrices from "views/products/EditPrices";
import ShowCustomer from "views/customers/CustomerShow";
import AddCustomer from "views/customers/AddCustomer";
import EditCustomer from "views/customers/EditCustomer";
import Inventory from "views/products/inventory";
import { Toaster } from "react-hot-toast";
import ProductsStockSettings from "components/settings/ProductsStockSettings";
import ServerError from "views/ServerError";
import ProductsReviews from "views/productsReviews/Index";
import StoreShipping from "views/settings/StoreShipping";

import Index from "views/faqs/Index";
import Add from "views/faqs/Add";
import EditFaq from "views/faqs/Edit";

// import FaqsCategories from "views/faqs/categories/Index";
// import AddFaqCategory from "views/faqs/categories/Add";
// import EditFaqCategory from "views/faqs/categories/Edit";
import DeliveryCompaniesRules from "components/settings/storeShipping/DeliveryCompaniesRules";
import StorePaymentMethods from "views/settings/StorePaymentMethods";
import StoreOptions from "views/settings/StoreOptions";
import StoreNotifications from "views/settings/StoreNotifications";
import StorePaymentRules from "components/settings/storePaymentRules";
import StockSettings from "components/settings/StockSettings";
import StoreReceipts from "views/settings/StoreReceipts";
// import StoreDomain from "views/settings/StoreDomain";
import StoreDomainTable from "views/settings/domainSettings/Index";
import StoreApps from "views/settings/StoreApps";

const App = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  //selectors
  const { isLoggedIn } = useSelector((state) => state?.authentication);

  useEffect(() => {
    if (token) {
      dispatch(getCurrentUser());
      // dispatch(getSettings("languageSettings"));
    }
  }, [token, dispatch]);

  const RenderApp = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route
                path="/"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <AuthRoute>
                    <Dashboard />
                  </AuthRoute>
                }
              />
              <Route
                path="/stores"
                element={
                  <AuthRoute>
                    <StoresIndex />
                  </AuthRoute>
                }
              />
              <Route
                path="/stores/addstore"
                element={
                  <AuthRoute>
                    <AddStore />
                  </AuthRoute>
                }
              />
              <Route
                path="/stores/:id"
                element={
                  <AuthRoute>
                    <EditStore />
                  </AuthRoute>
                }
              />

              <Route
                path="/stores/show/:id"
                element={
                  <AuthRoute>
                    <ShowStore />
                  </AuthRoute>
                }
              />

              {/* <Route
                path="/users"
                element={
                  <AuthRoute>
                    <UsersIndex />
                  </AuthRoute>
                }
              />
              <Route
                path="/users/adduser"
                element={
                  <AuthRoute>
                    <AddUser />
                  </AuthRoute>
                }
              />

              <Route
                path="/users/:id"
                element={
                  <AuthRoute>
                    <EditUser />
                  </AuthRoute>
                }
              /> */}

              <Route
                path="/categories"
                element={
                  <AuthRoute>
                    <CategoriesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/addcategory"
                element={
                  <AuthRoute>
                    <AddCategory />
                  </AuthRoute>
                }
              />

              <Route
                path="/categories/:id"
                element={
                  <AuthRoute>
                    <EditCategory />
                  </AuthRoute>
                }
              />
              {/* Visits - reports */}

              <Route
                path="/visits-reports"
                element={
                  <AuthRoute>
                    <VisitsReports />
                  </AuthRoute>
                }
              />

              <Route
                path="/products/inventory"
                element={
                  <AuthRoute>
                    <Inventory />
                  </AuthRoute>
                }
              />
              <Route
                path="/products"
                element={
                  <AuthRoute>
                    <ProductsIndex />
                  </AuthRoute>
                }
              >
                <Route
                  path="/products/:id"
                  element={
                    <AuthRoute>
                      <ProductModal />
                    </AuthRoute>
                  }
                />
              </Route>
              <Route path="/products/edit-prices" element={<EditPrices />} />

              <Route
                path="/brands"
                element={
                  <AuthRoute>
                    <BrandsIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/brands/addbrand"
                element={
                  <AuthRoute>
                    <AddBrand />
                  </AuthRoute>
                }
              />
              <Route
                path="/brands/:id"
                element={
                  <AuthRoute>
                    <EditBrand />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs"
                element={
                  <AuthRoute>
                    <BlogsIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/addblog"
                element={
                  <AuthRoute>
                    <AddBlog />
                  </AuthRoute>
                }
              />
              <Route
                path="/blogs/:id"
                element={
                  <AuthRoute>
                    <EditBlog />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/show/:id"
                element={
                  <AuthRoute>
                    <ShowBlog />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/categories"
                element={
                  <AuthRoute>
                    <BlogCategoriesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/blogs/categories/addcategory"
                element={
                  <AuthRoute>
                    <AddBlogCategory />
                  </AuthRoute>
                }
              />
              <Route
                path="/blogs/categories/:id"
                element={
                  <AuthRoute>
                    <EditBlogCategory />
                  </AuthRoute>
                }
              />

              <Route
                path="/faqs"
                element={
                  <AuthRoute>
                    <Index />
                  </AuthRoute>
                }
              />

              <Route
                path="/faqs/add"
                element={
                  <AuthRoute>
                    <Add />
                  </AuthRoute>
                }
              />
              <Route
                path="/faqs/:id"
                element={
                  <AuthRoute>
                    <EditFaq />
                  </AuthRoute>
                }
              />

              {/* <Route
                path="/faqs/categories"
                element={
                  <AuthRoute>
                    <FaqsCategories />
                  </AuthRoute>
                }
              />

              <Route
                path="/faqs/categories/add"
                element={
                  <AuthRoute>
                    <AddFaqCategory />
                  </AuthRoute>
                }
              />
              <Route
                path="/faqs/categories/:id"
                element={
                  <AuthRoute>
                    <EditFaqCategory />
                  </AuthRoute>
                }
              /> */}

              <Route
                path="/themeTemplates"
                element={
                  <AuthRoute>
                    <ThemeTemplateIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/storethemes"
                element={
                  <AuthRoute>
                    <StoreThemesIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/storethemes/builder/edit/:themeId"
                element={
                  <AuthRoute>
                    <Edit />
                  </AuthRoute>
                }
              />

              <Route
                path="/orders"
                element={
                  <AuthRoute>
                    <OrdersIndex />
                  </AuthRoute>
                }
              />

              <Route
                path="/orders/show/:id"
                element={
                  <AuthRoute>
                    <ShowOrder />
                  </AuthRoute>
                }
              />

              <Route
                path="/customers"
                element={
                  <AuthRoute>
                    <Customers />
                  </AuthRoute>
                }
              />
              <Route
                path="/customers/show/:id"
                element={
                  <AuthRoute>
                    <ShowCustomer />
                  </AuthRoute>
                }
              />
              <Route
                path="/customers/addCustomer"
                element={
                  <AuthRoute>
                    <AddCustomer />
                  </AuthRoute>
                }
              />

              <Route
                path="/customers/edit/:id"
                element={
                  <AuthRoute>
                    <EditCustomer />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings"
                element={
                  <AuthRoute>
                    <Settings />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/basicSettings"
                element={
                  <AuthRoute>
                    <BasicSettings />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/currencies"
                element={
                  <AuthRoute>
                    <StoreCurrencies />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/languages"
                element={
                  <AuthRoute>
                    <StoreLangs />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/store-wallet"
                element={
                  <AuthRoute>
                    <StorePayments />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/storePaymentMethods"
                element={
                  <AuthRoute>
                    <StorePaymentMethods />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/store-shipping"
                element={
                  <AuthRoute>
                    <StoreShipping />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/store-options"
                element={
                  <AuthRoute>
                    <StoreOptions />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/store-shipping/deliveryCompaniesRules"
                element={
                  <AuthRoute>
                    <DeliveryCompaniesRules />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/seo"
                element={
                  <AuthRoute>
                    <StoreSeo />
                  </AuthRoute>
                }
              />

              {/* <Route
                path="/settings/domain-1"
                element={
                  <AuthRoute>
                    <StoreDomain />
                  </AuthRoute>
                }
              /> */}

              <Route
                path="/settings/domain"
                element={
                  <AuthRoute>
                    <StoreDomainTable />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/maintenance"
                element={
                  <AuthRoute>
                    <StoreMaintenance />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/storeNotifications"
                element={
                  <AuthRoute>
                    <StoreNotifications />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/storePaymentRules"
                element={
                  <AuthRoute>
                    <StorePaymentRules />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/productsSettings"
                element={
                  <AuthRoute>
                    <ProductsStockSettings />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/stockSettings"
                element={
                  <AuthRoute>
                    <StockSettings />
                  </AuthRoute>
                }
              />
              <Route
                path="/settings/storeReceipts"
                element={
                  <AuthRoute>
                    <StoreReceipts />
                  </AuthRoute>
                }
              />

              <Route
                path="/settings/storeApps"
                element={
                  <AuthRoute>
                    <StoreApps />
                  </AuthRoute>
                }
              />
              {/* </Route> */}

              <Route
                path="/coupons"
                element={
                  <AuthRoute>
                    <CouponsIndex />
                  </AuthRoute>
                }
              />
              <Route
                path="/productsReviews"
                element={
                  <AuthRoute>
                    <ProductsReviews />
                  </AuthRoute>
                }
              />

              {/* pages */}
              <Route
                path="/pages"
                element={
                  <AuthRoute>
                    <Pages />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/addPage"
                element={
                  <AuthRoute>
                    <AddPage />
                  </AuthRoute>
                }
              />

              <Route
                path="/pages/:id"
                element={
                  <AuthRoute>
                    <EditPage />
                  </AuthRoute>
                }
              />

              <Route
                path="/profile"
                element={
                  <AuthRoute>
                    <Profile />
                  </AuthRoute>
                }
              />

              <Route path="/error/:code" element={<ServerError />} />

              <Route path="*" element={<NotFound />} />
            </Route>
            <Route
              path="/logout"
              element={
                <AuthRoute>
                  <Logout />
                </AuthRoute>
              }
            />
            <Route
              path="/login"
              element={
                <GuestRoute>
                  <Login />
                </GuestRoute>
              }
            />
          </Routes>
        </BrowserRouter>
        <Toaster
          position="top-left"
          reverseOrder={false}
          containerClassName="tjaara-toast"
        />
      </Suspense>
    );
  };

  if (!token) return RenderApp();
  if (isLoggedIn !== null) return RenderApp();
  return <Loader />;
};

export default App;
