import axios from "axios";
import { REACT_APP_API_URL } from "config";

const server = () => {
  const headers = {};
  const token = localStorage.getItem("token") || "";
  const store = localStorage.getItem("store") || "";
  if (token) headers.Authorization = token;
  if (store) headers.store = store;

  return axios.create({
    baseURL: REACT_APP_API_URL,
    headers,
  });
};

export default server;
