import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ReactComponent as GlobIcon } from "assets/svgs/product/global-edit.svg";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { toastErrorMessage } from "helpers/toaster/toastErrorMessage";
import { addDomain, editDomain } from "store/actions";
import { toastSuccessMessage } from "helpers/toaster/toastSuccessMessage";

const DomainModal = ({
  show,
  setShow,
  handleClose,
  handleShow,
  selectedDomain,
  modalType,
}) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();

  const validateAndExtractDomain = (inputUrl) => {
    try {
      const cleanedInput = inputUrl.trim().toLowerCase();
      const domainPattern =
        /^(?!www\.)([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})*$/;

      if (/^https?:\/\//i.test(cleanedInput)) {
        throw new Error("Please enter domain without http:// or https://");
      }

      if (!domainPattern.test(cleanedInput)) {
        throw new Error(
          "Please enter a valid domain (e.g., domain.com or sub.domain.com)"
        );
      }

      const processedUrl = `http://${cleanedInput}`;
      return new URL(processedUrl).hostname;
    } catch (error) {
      toastErrorMessage({ error: error.message });
      throw error;
    }
  };

  useEffect(() => {
    if (modalType === "edit" && selectedDomain) {
      reset({
        domain: selectedDomain.domain,
      });
    } else {
      reset({
        domain: "",
      });
    }
  }, [selectedDomain, modalType, reset]);

  const onSubmit = async (data) => {
    const domainAddress = validateAndExtractDomain(data.domain);

    console.log(data);

    if (modalType === "add") {
      dispatch(
        addDomain({
          domain: domainAddress,
          toastSuccessMessage,
          handleClose,
        })
      );
    } else if (modalType === "edit" && selectedDomain) {
      dispatch(
        editDomain({
          id: selectedDomain.id,
          domain: domainAddress,
          toastSuccessMessage,
          handleClose,
        })
      );
    }
  };

  return (
    <Modal
      dialogClassName="productDataModal transfere-domain"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      // size="lg"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <GlobIcon />
            <FormattedMessage id="linkOutsideTjaara" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="form-group">
          <Row className="form-body">
            <Col xs={12} className="form-group p-0 px-2">
              <h5>
                <FormattedMessage id="domainAddress" />
              </h5>
              <div className="">
                <input
                  className="form-control form-outline"
                  placeholder="domain.com"
                  type="text"
                  style={{
                    paddingInlineStart: "20px",
                    direction: "ltr",
                  }}
                  {...register("domain", {
                    required: true,
                  })}
                />
                <GlobIcon fill="#E4E4E4" />
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline"
            onClick={handleClose}
          >
            إلغاء
          </button>
          <button type="submit" className="btn btn-blue">
            حفظ
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default DomainModal;
