import server from "./server";

export const getNotificationsApi = async () => {
  const store = localStorage.getItem("store");
  let url = `/notifications`;
  if (store) url = `/notifications?store=${store}`;
  const response = await server().get(url);
  return response.data;
};

export const markNotificationAsReadApi = async (id) => {
  const response = await server().put(`/notifications/${id}/read`);
  return response.data;
};

export const markNotificationAsUnreadApi = async (id) => {
  const response = await server().put(`/notifications/${id}/unread`);
  return response.data;
};

export const markAllNotificationsAsReadApi = async () => {
  const store = localStorage.getItem("store");
  let url = `/notifications/readAll`;
  if (store) url = `/notifications/readAll?store=${store}`;
  const response = await server().put(url);
  return response.data;
};
