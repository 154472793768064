import { REACT_APP_API_URL } from "config";

export const handleImage = (image) => {
  if (image) {
    if (image?.includes("https://") || image?.includes("http://")) return image;
    if (!image.startsWith("/")) image = `/${image}`;
    return `${`${REACT_APP_API_URL}`?.replace("/api", "")}${image}`;
  }
  return "";
};

export const cleanImagelink = (image = "") => image;
// "uploads/" + image.split("/").pop();

export const checkIfUserHasPermission = (
  userPermissions = [],
  testedPermission
) => {
  if (userPermissions.length === 0) return false;
  return userPermissions.includes(testedPermission);
};

export const handleSearchParamsChange = (search, params) => {
  const url = new URLSearchParams("");
  search = { ...search, ...params };
  Object.entries(search).map(([key, value]) => {
    return url.set(key, value);
  });
  return "?" + url;
};

export const themeSwitcherFunction = () => {
  document.querySelector(".darkSwitcher")?.classList.toggle("fire");
  document.querySelector("body")?.classList.toggle("dark");
};

export const sidebarToggle = () => {
  document.querySelector("body").classList.toggle("sidebarToggled");
  document.querySelector(".overlay-s").classList.toggle("fire");
  document.querySelector("html").classList.toggle("offScroll");
};

export const sidebarToggleFunction = () => {
  document.querySelector("body")?.classList.remove("sidebarToggled");
  document.querySelector(".overlay-s")?.classList.remove("fire");
  document.querySelector("html")?.classList.remove("offScroll");
};

export const handleIsoDate = (isoDate) => {
  const date = new Date(isoDate);

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  if (year && month && day) return `${year}-${month}-${day}`;
};

export const getOptionsFrom = (data, locale) =>
  data?.length > 0
    ? data?.map((c) => ({
        label: c?.name?.[locale] || c?.name,
        value: c?.id,
      }))
    : {
        label: data?.name?.[locale] || data?.name,
        value: data?.id,
      };

export const getId = (obj) =>
  obj?._id || obj?.id || `${obj !== undefined ? obj : ""}`;

export const validateKeysValues = (obj, values = false) => {
  let res = Object.keys(obj)?.map((key) => obj?.[key]?.length > 0);

  if (values)
    res = values?.map(({ language: { locale } }) => obj?.[locale]?.length > 0);

  res = res?.find((e) => !e);
  if (res === undefined) return true;

  return res;
};

export const globalToastOptions = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const isImage = (url) => {
  const extension = url?.split("/")?.pop()?.split(".")?.pop();

  if (
    extension === "jpg" ||
    extension === "png" ||
    extension === "jpeg" ||
    extension === "svg" ||
    extension === "gif" ||
    extension === "webp"
  )
    return true;
  return extension;
};

export const formatDateYmd = (date) => {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const day = d.getDate();
  if (year && month && day) return `${year}-${month}-${day}`;
};

export const getPublicImage = (arr) =>
  (arr?.variants || arr)?.find((e) => e?.includes("public"));

export const getImageVariant = (url, variant = "public") => {
  if (!url) return;
  const rawUrl = url.split("public")[0];
  if (!rawUrl.includes("https://imagedelivery.net")) return url;
  return rawUrl + variant;
};

export const getFullDate = (date, locale) => {
  if (!date) return;
  return new Date(date).toLocaleDateString(
    locale === "ar" ? "ar-EG" : "en-US",
    {
      month: "long",
      day: "numeric",
      year: "numeric",
    }
  );
};
